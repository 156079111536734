import PropTypes from 'prop-types';
import React from 'react';
import useResponsiveStyle from '../../hooks/useResponsiveStyle';
import CircleButton from '../UI/CircleButton/CircleButton';
import Paragraph from '../UI/Paragraph';
import RoundDashCard from '../UI/RoundDashCard';

const defaultStyles = [
  {
    mobile: { position: 'absolute', bottom: '28px' },
    tablet: { position: 'absolute', bottom: '32px' },
    desktop: { position: 'absolute', bottom: '40px' }
  },
  {
    mobile: {
      padding: '20px 32px 100px 32px',
      height: '100%',
      position: 'relative',
      margin: '16px 8px'
    },
    tablet: { padding: '20px 32px 120px 32px', height: '100%', position: 'relative' },
    desktop: { padding: '20px 32px 120px 32px', height: '100%', position: 'relative' }
  }
];
const OrganiserCard = props => {
  const { logoUrl, description, learnMore, url } = props;

  const [btnStyles, cardStyles] = useResponsiveStyle(defaultStyles);
  return (
    <RoundDashCard style={cardStyles}>
      <div
        style={{ display: 'flex', justifyContent: 'center', cursor: 'pointer', margin: '16px 0' }}
      >
        <a
          href={url}
          target="_blank"
          rel="noopener noreferrer"
          style={{ display: 'flex', justifyContent: 'center' }}
        >
          <img
            src={logoUrl}
            alt="logo"
            style={{
              height: '70px',
              resize: 'contain'
            }}
          />
        </a>
      </div>
      {description.split('\n').map((item, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <Paragraph key={i} style={{ marginTop: '18px' }}>
          {item}
        </Paragraph>
      ))}

      <div style={btnStyles}>
        <CircleButton label="learn more" secondary onClick={learnMore} />
      </div>
    </RoundDashCard>
  );
};
OrganiserCard.propTypes = {
  logoUrl: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  learnMore: PropTypes.func.isRequired,
  url: PropTypes.string
};

OrganiserCard.defaultProps = {
  url: ''
};
export default OrganiserCard;
