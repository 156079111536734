import React from 'react';
import PropTypes from 'prop-types';
import RoundDashCard from '../UI/RoundDashCard';
import { primaryColor } from '../../assets/css/variables';
import Paragraph from '../UI/Paragraph';
import styles from './StepCard.module.css';

const StepCard = props => {
  const { title, description, step } = props;
  return (
    <RoundDashCard style={{ padding: '32px', height: '100%' }}>
      <div
        className={styles.icon}
        style={{
          textAlign: 'center',
          verticalAlign: 'center',
          color: 'white',
          fontFamily: 'helvetica',
          backgroundColor: primaryColor
        }}
      >
        {step}
      </div>
      <div
        className={styles.title}
        style={{
          textAlign: 'center',
          color: primaryColor,
          letterSpacing: '1px',
          fontFamily: 'helvetica'
        }}
      >
        {title.toUpperCase()}
      </div>
      <Paragraph>{description}</Paragraph>
    </RoundDashCard>
  );
};
StepCard.propTypes = {
  step: PropTypes.number,
  title: PropTypes.string,
  description: PropTypes.string
};
StepCard.defaultProps = {
  step: 0,
  title: '',
  description: ''
};
export default StepCard;
