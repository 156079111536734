/* eslint-disable react/jsx-one-expression-per-line */
import React, { useEffect, useState } from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel
} from 'react-accessible-accordion';
import { Col, Row } from 'react-bootstrap';
import Slider from 'react-slick';
import { titleMarginBottom } from '../../assets/css/variables';
import { ReactComponent as Open } from '../../assets/icons/Expand.svg';
import { ReactComponent as Close } from '../../assets/icons/Minimise.svg';
import cityPlanningIcon from '../../assets/line_icons/City_Planning_1.svg';
import dataIcon from '../../assets/line_icons/Data_1.svg';
import TechnologyIcon from '../../assets/line_icons/Technology_1.svg';
import ChallengeTheme from '../ChallengeTheme/ChallengeTheme';
import Paragraph from '../UI/Paragraph';
import SectionTitle from '../UI/SectionTitle';
import TextLink from '../UI/TextLink/TextLink';
import styles from './Challenge.module.css';
import CircleBadge from './CircleBadge/CircleBadge';
import Smallicon from './Smallicon';

const IconDivStyles = {
  position: 'absolute',
  width: '530px',
  height: '550px',
  padding: 0,
  margin: 0,
  right: 0
};

const settings = {
  dots: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  infinite: true,
  centerMode: true,
  centerPadding: '60px'
};

const smallIcons = [
  {
    id: 1,
    title: 'City Planning',
    iconId: 'challenge-city-planning-icon',
    icon: cityPlanningIcon,
    content:
      'The organized process of using data and technology for the design and development of a city and its environment for efficient use of available resources to drive the betterment of the city’s inhabitants.'
  },
  {
    id: 2,
    title: 'Data',
    iconId: 'challenge-data-icon',
    icon: dataIcon,
    content:
      'Units of information and a valuable and powerful resource in today’s world, can be used to generate insights and solutions to address various modern-world problems.'
  },
  {
    id: 3,
    title: 'Tech',
    iconId: 'challenge-tech-icon',
    icon: TechnologyIcon,
    content:
      'Tools to process raw data into functional forms, and can be integrated into daily life to enhance the quality of life of people, or be applied to planning and simulations for greater decision making. '
  }
];

const circleBadges = [
  {
    id: 1,
    radius: 128,
    bgColor: 'rgb(254,192,41)',
    colCount: 2,
    text: [
      <span key="11" className={styles.badgeTextMd}>
        Mentorship
      </span>,
      <span key="12" className={styles.badgeTextMd}>
        Opportunities
      </span>
    ]
  },
  {
    id: 2,
    radius: 116,
    bgColor: 'rgb(61,61,61)',
    colCount: 2,
    text: [
      <span key="21" className={styles.badgeTextMd}>
        Incubation
      </span>,
      <span key="22" className={styles.badgeTextMd}>
        Program
      </span>,
      <span key="23" className={styles.badgeTextMd}>
        Opportunities
      </span>
    ]
  },
  {
    id: 3,
    radius: 176,
    bgColor: 'rgb(235,87,58)',
    colCount: 4,
    text: [
      <span key="31" className={styles.badgeTextMd}>
        A Total of
      </span>,
      <div key="311">
        <span
          className={styles.badgeTextMd}
          style={{
            float: 'left',
            marginTop: '20px'
          }}
        >
          US
        </span>
        <span className={styles.badgeTextLg}>$1.5M</span>
      </div>,
      <span key="32" className={styles.badgeTextMd}>
        To be Won*
      </span>,
      <span key="33" className={styles.badgeTextXs} style={{ marginTop: '10px' }}>
        *Terms & Conditions Apply
      </span>
    ]
  },
  {
    id: 4,
    radius: 128,
    bgColor: 'rgb(110,178,189)',
    colCount: 3,
    text: [
      <span key="41" className={styles.badgeTextMd}>
        TRIAL
      </span>,
      <span key="42" className={styles.badgeTextMd}>
        IMPLEMENTATION
      </span>,
      <span key="43" className={styles.badgeTextMd}>
        IN
      </span>,
      <span key="44" className={styles.badgeTextMd}>
        KUALA LUMPUR
      </span>
    ]
  }
];

const Challenge = () => {
  const [bigIconTitle, setBigIconTitle] = useState('City Planning');
  const [isHovering, setIsHovering] = useState(false);

  const activeContent = smallIcons.filter(item => {
    return item.title === bigIconTitle;
  });

  const myCallback = (hoverTitle, isHover) => {
    setBigIconTitle(hoverTitle);
    setIsHovering(isHover);
  };

  useEffect(() => {
    const blinker = setInterval(() => {
      switch (true) {
        case bigIconTitle === 'City Planning':
          myCallback('Data', false);
          break;
        case bigIconTitle === 'Data':
          myCallback('Tech', false);
          break;
        case bigIconTitle === 'Tech':
          myCallback('City Planning', false);
          break;
        default:
          break;
      }
    }, 2000);

    if (isHovering) {
      clearInterval(blinker);
    }

    return () => {
      clearInterval(blinker);
    };
  }, [isHovering, bigIconTitle]);

  const [openPanels, setOpenPanels] = useState([]);

  const isPanelOpen = id => {
    return !!openPanels.includes(id);
  };

  return (
    <div className="subContainer" style={{ overflow: 'hidden', position: 'relative' }}>
      <div className={styles.curve} />
      <Row>
        <Col lg={6} md={12} className={styles.challengeTextContainer}>
          <div className={styles.challengeTextTitle}>The Challenge</div>
          <br />
          <div className={styles.challengeTextSubTitle}>
            How might we harness the power of data and technology to reimagine urban infrastructure?
          </div>
          <br />
          <p className={styles.challengeTextParagraph}>
            Cities of today will see more rapid changes in the next 10 years than the last 100.
            While population growth and urbanisation has created significant opportunities for
            social and economic development, it has also exerted significant pressure on
            infrastructure and resources.
            <br />
            <br />
            The{' '}
            <strong>
              <span className={styles.redText}>C</span>ity <span className={styles.redText}>A</span>
              rchitecture for <span className={styles.redText}>T</span>omorrow{' '}
              <span className={styles.redText}>Ch</span>allenge (CATCH)
            </strong>{' '}
            is organised by <strong>Toyota Mobility Foundation</strong> and co-developed by{' '}
            <strong>Deloitte Future of Mobility Solution Centre</strong>, to drive intelligent
            data-driven, connected solutions towards more efficient, innovative, secure and
            sustainable cities. <br />
            <br />
            The challenge aims to inspire and invite global teams – entrepreneurs, startups,
            academic/research institutions, innovators etc. – to{' '}
            <strong>
              reimagine and design future city infrastructures through dynamic, intelligent,
              data-driven & connected solutions
            </strong>
            .
            <br />
            <br />
            Find out more about
            <TextLink
              href="http://toyotamobilityfoundation.org/en/"
              target="_blank"
              style={{ marginLeft: '4px' }}
            >
              Toyota Mobility Foundation
            </TextLink>
          </p>
        </Col>
        <Col lg={6} md={12} className={styles.challengeIconsDesktop}>
          <div style={IconDivStyles}>
            <div className={styles.DesContainer}>
              <div className={styles.bigIconTitle}>{bigIconTitle}</div>
              <div className={styles.challengeContent}>{activeContent[0].content}</div>
            </div>
            {smallIcons.length > 0 &&
              smallIcons.map(item => (
                <Smallicon
                  key={`smallicon${item.id}`}
                  iconId={item.iconId}
                  bgColor={item.bgColor}
                  title={item.title}
                  callbackFromParent={myCallback}
                  hoverState={item.title === bigIconTitle}
                />
              ))}
          </div>
        </Col>

        <Col lg={6} md={12} className={styles.challengeIconsMobile}>
          <div className={[styles.challengeAccordion].join(' ')}>
            <Accordion
              allowMultipleExpanded
              allowZeroExpanded
              onChange={text => setOpenPanels(text)}
            >
              {smallIcons.length > 0 &&
                smallIcons.map(data => (
                  <AccordionItem key={data.id} uuid={data.id}>
                    <AccordionItemHeading
                      className={styles.accordionHead}
                      style={{ borderWidth: isPanelOpen(data.id) ? '0' : '1px' }}
                    >
                      <AccordionItemButton>
                        <img src={data.icon} alt="icon" style={{ height: '48px' }} />
                        <div style={{ textAlign: 'left', width: '100%', paddingLeft: '26px' }}>
                          {data.title.toUpperCase()}
                        </div>
                        <div>
                          {isPanelOpen(data.id) ? (
                            <Close height={24} width={24} />
                          ) : (
                            <Open height={24} width={24} />
                          )}
                        </div>
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel className={styles.accordionContent}>
                      <Paragraph style={{ textAlign: 'justify' }}>{data.content}</Paragraph>
                    </AccordionItemPanel>
                  </AccordionItem>
                ))}
            </Accordion>
          </div>
        </Col>
      </Row>

      <div className={styles.challengeThemeContainer}>
        <ChallengeTheme />
      </div>
      <div style={{ marginTop: '68px' }}>
        <SectionTitle title="In a Snapshot" customStyles={{ marginBottom: titleMarginBottom }} />
      </div>
      <Row className={styles.circleBadgeRow}>
        {circleBadges.length > 0 &&
          circleBadges.map(item => (
            <div key={item.id}>
              <CircleBadge
                id={`circlebadge${item.id}`}
                colCount={item.colCount}
                radius={item.radius}
                bgColor={item.bgColor}
                text={item.text}
              />
            </div>
          ))}
      </Row>
      <Row className={styles.circleBadgeRowMobile}>
        <Slider {...settings}>
          {circleBadges.length > 0 &&
            circleBadges.map(item => (
              <div key={item.id}>
                <CircleBadge
                  id={`circlebadge${item.id}`}
                  colCount={item.colCount}
                  radius={item.radius}
                  bgColor={item.bgColor}
                  text={item.text}
                />
              </div>
            ))}
        </Slider>
      </Row>
    </div>
  );
};

export default Challenge;
