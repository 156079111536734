import PropTypes from 'prop-types';
import React, { useState } from 'react';
import ClipLoader from 'react-spinners/ClipLoader';
import { primaryColor } from '../../../assets/css/variables';
import { ReactComponent as Error } from '../../../assets/icons/Error.svg';
import styles from './CircleInput.module.css';

const areEqual = (prevProps, nextProps) => {
  return prevProps.isBtnLoading === nextProps.isBtnLoading && prevProps.value === nextProps.value;
};

const CircleInput = props => {
  const { placeHolder, type, value, onChange, validationFn, Btn, isBtnLoading, label } = props;
  const [touch, setTouch] = useState(false);
  const [errMsg, setErrMsg] = useState(null);
  const inputBlurHandler = event => {
    setTouch(true);
    setErrMsg(validationFn(event.target.value));
  };
  const inputChangeHandler = event => {
    setErrMsg(null);
    onChange(event.target.value);
  };
  let containerClass = touch && errMsg ? styles.inputContainerError : styles.inputContainer;
  containerClass = Btn ? styles.inputContainerWithBtn : containerClass;
  return (
    <>
      {label ? <span className={styles.inputLabel}>{label}</span> : null}
      <div className={containerClass}>
        <input
          className={styles.input}
          placeholder={placeHolder}
          type={type}
          value={value}
          onBlur={inputBlurHandler}
          onChange={inputChangeHandler}
        />
        {isBtnLoading ? (
          <div
            style={{
              backgroundColor: primaryColor,
              height: 32,
              width: 32,
              borderRadius: 16,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <ClipLoader size={16} color="white" loading />
          </div>
        ) : (
          Btn
        )}
        {touch && errMsg ? <Error height={24} width={24} /> : null}
      </div>
      <div className={styles.errMsg}>{errMsg}</div>
    </>
  );
};
CircleInput.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  label: PropTypes.string,
  placeHolder: PropTypes.string,
  type: PropTypes.string,
  validationFn: PropTypes.func,
  Btn: PropTypes.node,
  isBtnLoading: PropTypes.bool
};
CircleInput.defaultProps = {
  value: '',
  onChange: () => {},
  label: null,
  placeHolder: null,
  type: 'text',
  validationFn: () => null,
  Btn: null,
  isBtnLoading: false
};

export default React.memo(CircleInput, areEqual);
