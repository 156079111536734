import React from 'react';
import PropTypes from 'prop-types';
import { primaryColor } from '../../assets/css/variables';
import Paragraph from './Paragraph';
import RoundModal from './RoundModal/RoundModal';
import CircleButton from './CircleButton/CircleButton';

const MessageModal = props => {
  const { showModal, onHide, icon, header, content, btnLabel } = props;
  return (
    <RoundModal showModal={showModal} onHide={onHide}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: '40px 80px'
        }}
      >
        <div>
          <img src={icon} height={100} width={100} alt="modal-icon" />
        </div>
        <div
          style={{
            fontFamily: 'helvetica',
            fontStyle: 'normal',
            fontSize: '1.375rem',
            lineHeight: '28px',
            textAlign: 'center',
            letterSpacing: '1px',
            textTransform: 'uppercase',
            color: primaryColor,
            marginTop: '48px',
            marginBottom: '16px'
          }}
        >
          {header}
        </div>
        <Paragraph>{content}</Paragraph>
        <div style={{ marginTop: '24px' }}>
          <CircleButton label={btnLabel} onClick={onHide} />
        </div>
      </div>
    </RoundModal>
  );
};
MessageModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  onHide: PropTypes.func,
  icon: PropTypes.node.isRequired,
  header: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  btnLabel: PropTypes.string.isRequired
};
MessageModal.defaultProps = {
  onHide: () => {}
};
export default MessageModal;
