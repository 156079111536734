import React, { useRef, useEffect } from 'react';
import { TweenMax, Linear } from 'gsap';
import PropTypes from 'prop-types';
import { Transition } from 'react-transition-group';

import Bus from '../../assets/img/vehicles/Bus.svg';
import Train from '../../assets/img/vehicles/Train.svg';

import AutonomousCarBlue from '../../assets/img/vehicles/AutonomousCarBlue.svg';
import AutonomousCarRed from '../../assets/img/vehicles/AutonomousCarRed.svg';
import GreyCar from '../../assets/img/vehicles/GreyCar.svg';
import BrownLorry from '../../assets/img/vehicles/BrownLorry.svg';
import GreenScooterhuman from '../../assets/img/vehicles/GreenScooterhuman.svg';
import BlueCar from '../../assets/img/vehicles/BlueCar.svg';
import TealBikehuman from '../../assets/img/vehicles/TealBikehuman.svg';
import Orangetruck from '../../assets/img/vehicles/Orangetruck.svg';
import TealVan from '../../assets/img/vehicles/TealVan.svg';
import RedBikehuman from '../../assets/img/vehicles/RedBikehuman.svg';
import Taxi from '../../assets/img/vehicles/Taxi.svg';

const MovingCar = props => {
  // const [runDuration, setRunDuration] = useState(1);
  const { height, bottom, start, end, vehicleName, speed, zIndex, delay } = props;

  let vehicleImg = '';
  switch (true) {
    case vehicleName === 'GreyCar':
      vehicleImg = GreyCar;
      break;
    case vehicleName === 'BrownLorry':
      vehicleImg = BrownLorry;
      break;
    case vehicleName === 'AutonomousCarBlue':
      vehicleImg = AutonomousCarBlue;
      break;
    case vehicleName === 'GreenScooterhuman':
      vehicleImg = GreenScooterhuman;
      break;
    case vehicleName === 'BlueCar':
      vehicleImg = BlueCar;
      break;
    case vehicleName === 'Bus':
      vehicleImg = Bus;
      break;
    case vehicleName === 'TealBikehuman':
      vehicleImg = TealBikehuman;
      break;
    case vehicleName === 'Orangetruck':
      vehicleImg = Orangetruck;
      break;
    case vehicleName === 'AutonomousCarRed':
      vehicleImg = AutonomousCarRed;
      break;
    case vehicleName === 'TealVan':
      vehicleImg = TealVan;
      break;
    case vehicleName === 'RedBikehuman':
      vehicleImg = RedBikehuman;
      break;
    case vehicleName === 'Taxi':
      vehicleImg = Taxi;
      break;

    case vehicleName === 'Train':
      vehicleImg = Train;
      break;
    default:
      vehicleImg = '';
  }

  // const transformScale = start - end <= 0 ? 'scaleX(1)' : 'scaleX(-1)';

  let carItem = useRef(null);

  const runcars = () => {
    const runDuration = speed !== 0 ? speed : Math.floor(Math.random() * 10) + 6;

    TweenMax.fromTo(
      carItem,
      runDuration,
      { x: start },
      {
        x: end,
        ease: Linear.easeNone,
        repeat: -1,
        delay
      }
    );
  };

  const initFunctions = () => {
    runcars();
  };

  useEffect(initFunctions, []);
  return (
    <Transition in timeout={10000} unmountOnExit>
      <img
        ref={el => {
          carItem = el;
        }}
        src={vehicleImg}
        alt="car1"
        style={{
          height,
          left: '-160px',
          position: 'absolute',
          bottom,
          // transform: transformScale,
          zIndex: zIndex + 3
        }}
      />
    </Transition>
  );
};
MovingCar.propTypes = {
  height: PropTypes.number,
  bottom: PropTypes.number,
  start: PropTypes.number,
  end: PropTypes.number,
  vehicleName: PropTypes.string,
  speed: PropTypes.number,
  zIndex: PropTypes.number,
  delay: PropTypes.number
};

MovingCar.defaultProps = {
  height: 0,
  bottom: 0,
  start: -700,
  end: 700,
  vehicleName: '',
  speed: 0,
  zIndex: 0,
  delay: 0
};
export default MovingCar;
