import React from 'react';
import PropTypes from 'prop-types';
import { primaryColor } from '../../assets/css/variables';
import TextLink from '../UI/TextLink/TextLink';

const EventBubble = props => {
  const baseWidth = 180;

  const { top, left, width, date, titleText, linkText, linkURL } = props;

  const divWidth = width === 0 ? baseWidth : width;

  const containerDiv = {
    position: 'absolute',
    top: `${top}px`,
    left: `${left}px`,
    opacity: 1
  };

  const dottedBorderDiv = {
    width: `${divWidth}px`,
    padding: '10px',
    textAlign: 'center',
    borderRadius: '10px',
    border: `2px dashed ${primaryColor}`,
    backgroundColor: '#fff'
  };

  const dateStyle = {
    textTransform: 'uppercase',
    color: primaryColor,
    margin: '0px 0px 5px 0px'
  };

  const titleStyle = { fontSize: '15px', marginBottom: '5px' };

  const redDot = {
    position: 'absolute',
    bottom: '0px',
    margin: 'auto',
    left: 0,
    right: 0,
    height: '10px',
    width: '10px',
    borderRadius: '5px',
    backgroundColor: primaryColor
  };

  const smallVerticalLine = {
    height: '30px',
    width: `${divWidth / 2 + 1}px`,
    borderRight: `2px dashed ${primaryColor}`
  };

  return (
    <div style={containerDiv}>
      <div style={dottedBorderDiv}>
        <div style={dateStyle}>{date}</div>
        <div style={titleStyle}>{titleText}</div>
        {linkText !== '' ? (
          <TextLink href={linkURL} target="_blank" style={{ fontSize: '15px' }}>
            {linkText}
          </TextLink>
        ) : (
          ''
        )}
      </div>
      <div style={redDot} />
      <div style={smallVerticalLine} />
    </div>
  );
};

EventBubble.propTypes = {
  top: PropTypes.number,
  left: PropTypes.number,
  width: PropTypes.number,
  date: PropTypes.string,
  titleText: PropTypes.string,
  linkText: PropTypes.string,
  linkURL: PropTypes.string
};

EventBubble.defaultProps = {
  top: 0,
  left: 0,
  width: 0,
  date: '',
  titleText: '',
  linkText: '',
  linkURL: ''
};

export default EventBubble;
