import React, { useState, useEffect, useRef } from 'react';
import { Document, Page } from 'react-pdf';
import styles from './Handbook.module.css';
import lesson2 from '../../assets/handbook.pdf';

const preventFn = event => {
  event.preventDefault();
  return false;
};
const Handbook = () => {
  const [totalPages, setTotalPages] = useState(0);
  const { innerWidth } = window;
  const pageDiv = useRef(null);

  const onDocumentLoad = ({ numPages }) => {
    setTotalPages(numPages);
  };

  useEffect(() => {
    document.addEventListener('contextmenu', preventFn);
    return () => {
      document.removeEventListener('contextmenu', preventFn);
    };
  }, []);

  useEffect(() => {
    const ref = pageDiv.current;
    if (ref) {
      ref.addEventListener('mousedown', preventFn);
    }
    return () => {
      if (ref) {
        ref.removeEventListener('mousedown', preventFn);
      }
    };
  }, []);

  let scale = 2;
  if (innerWidth >= 1440) {
    scale = 3;
  }
  return (
    <div className={styles.container} ref={pageDiv}>
      <Document
        loading=""
        onLoadSuccess={onDocumentLoad}
        file={lesson2}
        className={styles.document}
      >
        {Array.from({ length: totalPages }, (x, i) => i + 1).map(i => (
          <Page key={i} loading="" scale={scale} pageNumber={i} className={styles.page} />
        ))}
      </Document>
    </div>
  );
};

export default Handbook;
