import React from 'react';
import Intro from '../components/IntroWithTimer/Intro';
import Challenge from '../components/Challenge/Challenge';
import ProblemStatement from '../components/ProblemStatement/ProblemStatement';
import Organiser from '../components/Organiser/Organiser';
import Partner from '../components/Partner/Partner';
import ContactUsForm from '../components/ContactUs/ContactUsForm';
import TimelineSection from '../components/Timeline/TimelineSection';
import News from '../components/News/News';

// const sectionStyle = {
//   paddingTop: '172px',
//   marginTop: '-112px'
// };

const MainPageContainer = () => {
  return (
    <div>
      <div id="home">
        <Intro />
      </div>
      <div id="challenge" className="sectionContainer">
        <Challenge />
      </div>
      <div id="organiser" className="sectionContainer">
        <Organiser />
      </div>
      <div className="sectionContainer">
        <ProblemStatement />
      </div>
      <div id="timeline" className="sectionContainer">
        <TimelineSection />
      </div>
      <div id="partner" className="sectionContainer">
        <Partner />
      </div>
      <div id="news" className="sectionContainer">
        <News />
      </div>
      <div id="contact" className="sectionContainer">
        <ContactUsForm />
      </div>
    </div>
  );
};

export default MainPageContainer;
