import { useEffect, useState } from 'react';

const useResponsiveStyle = styles => {
  const [screenWidth, setScreenWidth] = useState(
    typeof window !== 'undefined' ? window.innerWidth : 0
  );
  useEffect(() => {
    const updateDimensions = () => {
      const windowWidth = typeof window !== 'undefined' ? window.innerWidth : 0;
      setScreenWidth(windowWidth);
    };
    window.addEventListener('resize', updateDimensions);
    return () => {
      window.removeEventListener('resize', updateDimensions);
    };
  }, []);

  let size = 'mobile';
  if (screenWidth >= 768) {
    size = 'tablet';
  }
  if (screenWidth >= 1200) {
    size = 'desktop';
  }
  return styles.map(style => style[size]);
};

export default useResponsiveStyle;
