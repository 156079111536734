import React, { useState, useEffect, useRef } from 'react';
import { Line } from 'rc-progress';
import styles from './Timeline.module.css';
import './Timeline.css';
import SectionTitle from '../UI/SectionTitle';
import Paragraph from '../UI/Paragraph';
import TimelineBg from '../../assets/img/timeline/timeline-bg-final.png';
import CarImg from '../../assets/line_icons/toyota-prius.svg';
import EventBubbleMobile from './EventBubbleMobile';
import { primaryColor, lightGreyColor, pinkishGreyColor } from '../../assets/css/variables';
import useWindowDimensions from './useWindowDimensions';

const eventItemsBasicLeft = 750;
const eventItemsBasicTop = 115;
const linkTextJoinTheChallenge = 'Join the Challenge';
const progressBasicLeft = 100;

const eventItems = [
  {
    id: 1,
    top: eventItemsBasicTop,
    left: eventItemsBasicLeft,
    date: '13 Feb 2020',
    titleText: 'Launch of Challenge',
    linkText: '',
    linkURL: ''
  },
  {
    id: 2,
    top: eventItemsBasicTop,
    left: eventItemsBasicLeft + 200,
    date: '17 Feb 2020',
    titleText: 'Bangalore Roadshow',
    linkText: linkTextJoinTheChallenge,
    linkURL:
      'https://www.eventbrite.sg/e/city-architecture-for-tomorrow-roadshow-bengaluru-registration-93017167853'
  },
  {
    id: 3,
    top: eventItemsBasicTop,
    left: eventItemsBasicLeft + 405,
    date: '18 Feb 2020',
    titleText: 'Mumbai Roadshow',
    linkText: linkTextJoinTheChallenge,
    linkURL:
      'https://www.eventbrite.sg/e/city-architecture-for-tomorrow-roadshow-mumbai-registration-93219958405'
  },
  {
    id: 4,
    top: 100,
    left: eventItemsBasicLeft + 615,
    date: '20 Feb 2020',
    titleText: 'Jakarta Roadshow',
    linkText: linkTextJoinTheChallenge,
    linkURL:
      'https://www.eventbrite.sg/e/city-architecture-for-tomorrow-roadshow-jakarta-registration-94313238435'
  },
  {
    id: 5,
    top: eventItemsBasicTop,
    left: eventItemsBasicLeft + 915,
    width: 220,
    date: '21 Feb 2020',
    titleText: 'Ho Chi Minh City Roadshow',
    linkText: linkTextJoinTheChallenge,
    linkURL:
      'https://www.eventbrite.sg/e/city-architecture-for-tomorrow-roadshow-ho-chi-minh-city-registration-94322271453'
  },
  {
    id: 6,
    top: eventItemsBasicTop,
    left: eventItemsBasicLeft + 1268,
    date: '24 Feb Jan 2020',
    titleText: 'Bangkok Roadshow',
    linkText: linkTextJoinTheChallenge,
    linkURL:
      'https://www.eventbrite.sg/e/city-architecture-for-tomorrow-roadshow-bangkok-registration-94331651509'
  },
  // {
  //   id: 7,
  //   top: eventItemsBasicTop,
  //   left: eventItemsBasicLeft + 1530,
  //   date: '25 Feb 2020',
  //   titleText: 'Singapore Roadshow',
  //   linkText: linkTextJoinTheChallenge,
  //   linkURL:
  //     'https://www.eventbrite.sg/e/city-architecture-for-tomorrow-roadshow-singapore-registration-94333844067'
  // },
  {
    id: 8,
    top: 0,
    left: eventItemsBasicLeft + 1700,
    width: 210,
    date: '26 Feb 2020',
    titleText: 'Kuala Lumpur Roadshow',
    linkText: linkTextJoinTheChallenge,
    linkURL:
      'https://www.eventbrite.sg/e/city-architecture-for-tomorrow-roadshow-kuala-lumpur-registration-94334287393'
  },
  // {
  //   id: 9,
  //   top: eventItemsBasicTop - 25,
  //   left: eventItemsBasicLeft + 1925,
  //   date: '27 Feb 2020',
  //   titleText: 'Penang Roadshow',
  //   linkText: linkTextJoinTheChallenge,
  //   linkURL: ''
  // },
  // {
  //   id: 10,
  //   top: eventItemsBasicTop,
  //   left: eventItemsBasicLeft + 2145,
  //   date: '27 Feb 2020',
  //   titleText: 'Singapore Roadshow',
  //   linkText: linkTextJoinTheChallenge,
  //   linkURL:
  //     'https://www.eventbrite.sg/e/city-architecture-for-tomorrow-roadshow-singapore-tickets-96033636191'
  // },
  {
    id: 11,
    top: eventItemsBasicTop,
    left: eventItemsBasicLeft + 2385,
    date: '30 Jun 2020',
    titleText: 'Submission Deadline',
    linkText: 'Submit Your Entry',
    linkURL: 'https://tmf-catch.org/submit'
  },
  {
    id: 12,
    top: eventItemsBasicTop,
    left: eventItemsBasicLeft + 2650,
    date: 'Aug 2020',
    titleText: 'Announcement of Semi-finalists',
    linkText: '',
    linkURL: ''
  },
  {
    id: 13,
    top: eventItemsBasicTop,
    left: eventItemsBasicLeft + 2975,
    date: 'OCT – DEC 2020',
    titleText: 'POC Development',
    linkText: '',
    linkURL: ''
  },
  {
    id: 14,
    top: eventItemsBasicTop,
    left: eventItemsBasicLeft + 3290,
    date: 'JAN 2021',
    titleText: 'Announcement of Finalists',
    linkText: '',
    linkURL: ''
  },
  {
    id: 15,
    top: eventItemsBasicTop,
    left: eventItemsBasicLeft + 3595,
    date: 'MAR 2021',
    titleText: 'Incubation Week',
    linkText: '',
    linkURL: ''
  },
  {
    id: 16,
    top: eventItemsBasicTop,
    left: eventItemsBasicLeft + 3950,
    date: 'MAR - MAY 2021',
    titleText: 'MVP Development',
    linkText: '',
    linkURL: ''
  },
  {
    id: 17,
    top: eventItemsBasicTop,
    left: eventItemsBasicLeft + 4215,
    date: 'JUL 2021',
    titleText: 'Announcement of Challenge Winner',
    linkText: '',
    linkURL: ''
  },
  {
    id: 18,
    top: 0,
    left: eventItemsBasicLeft + 4525,
    width: 210,
    date: 'SEP 2021 - MAR 2022*',
    titleText: 'Trial Implementation',
    linkText: '',
    linkURL: ''
  }
];

const milestones = [
  {
    id: 1,
    percent: 0,
    title: 'Roadshows',
    date: '2020-02-13',
    left: progressBasicLeft
  },
  {
    id: 11,
    percent: 3,
    title: '',
    date: '2020-02-17'
  },
  {
    id: 12,
    percent: 6,
    title: '',
    date: '2020-02-18'
  },
  {
    id: 13,
    percent: 9,
    title: '',
    date: '2020-02-20'
  },
  {
    id: 14,
    percent: 12,
    title: '',
    date: '2020-02-21'
  },
  {
    id: 15,
    percent: 15,
    title: '',
    date: '2020-02-24'
  },
  {
    id: 16,
    percent: 18,
    title: '',
    date: '2020-02-25'
  },
  {
    id: 17,
    percent: 21,
    title: '',
    date: '2020-02-26'
  },

  {
    id: 18,
    percent: 21,
    title: '',
    date: '2020-02-27'
  },

  {
    id: 2,
    percent: 35,
    title: 'Submission',
    date: '2020-06-30',
    left: progressBasicLeft + 2380
  },
  {
    id: 3,
    percent: 50,
    scrollToHeight: 8000,
    title: 'POC',
    date: '2020-07-31',
    left: progressBasicLeft + 2975
  },
  {
    id: 4,
    percent: 75,
    title: 'Winner announcement',
    date: '2020-12-01',
    left: progressBasicLeft + 4205
  },
  {
    id: 5,
    percent: 100,
    title: 'Trial',
    date: '2020-12-15',
    left: progressBasicLeft + 4560
  }
];

let percent = 0;
const today = new Date();
milestones.map(item => {
  if (today > Date.parse(`${item.date}T00:00:00`)) {
    percent = item.percent;
  }
  return false;
});

const TimelineContent = () => {
  const { width } = useWindowDimensions();

  const timelineRef = useRef();
  const timelineDesktopRef = useRef();
  const [basicTop, setBasicTop] = useState(0);
  useEffect(() => {
    setBasicTop(timelineRef.current.offsetTop);
  }, [basicTop]);

  const [scrollDirection, setScrollDirection] = useState();

  const [lastScrollLeft, setLastScrollLeft] = useState(0);
  const listener = e => {
    if (
      (timelineDesktopRef.current.scrollLeft === 0 && e.deltaY < 0) ||
      (timelineDesktopRef.current.scrollLeft >= 5050 && e.deltaY > 0)
    ) {
      return true;
    }
    if (e.deltaY < 0) {
      e.preventDefault();
      timelineDesktopRef.current.scrollLeft -= 10;
      setScrollDirection('left');
    } else if (e.deltaY > 0) {
      e.preventDefault();
      timelineDesktopRef.current.scrollLeft += 10;
      setScrollDirection('right');
    }
    return true;
  };
  useEffect(() => {
    timelineDesktopRef.current.addEventListener('wheel', listener);
  });

  const horizontalListender = () => {
    setLastScrollLeft(timelineRef.current.scrollLeft);
    setScrollDirection(lastScrollLeft >= timelineRef.current.scrollLeft ? 'left' : 'right');
  };
  useEffect(() => {
    timelineRef.current.addEventListener('scroll', horizontalListender);
  });

  const componentTitle = (
    <>
      <SectionTitle title="Timeline" customStyles={{ textAlign: 'center' }} />
      <Paragraph>*Dates are subjected to changes</Paragraph>
    </>
  );

  const renderDesktop = (
    <div
      className={styles.container}
      style={{
        position: 'relative',
        paddingBottom: '100px',
        height: '800px',
        display: width >= 1200 ? 'block' : 'none'
      }}
    >
      <div>{componentTitle}</div>

      <div>
        <div
          style={{
            position: 'relative',
            marginTop: '30px',
            overflowX: 'scroll'
          }}
          ref={timelineDesktopRef}
        >
          <div
            style={{
              position: 'relative',
              height: '500px',
              width: '6490px',
              overflow: 'hidden'
            }}
          >
            <img
              src={TimelineBg}
              alt="timeline-bg"
              style={{
                position: 'absolute',
                height: '500px',
                width: '6490px'
              }}
            />
          </div>

          {eventItems.map(item => (
            <EventBubbleMobile
              key={item.id}
              id={item.id}
              top={item.top}
              left={item.left}
              width={item.width}
              date={item.date}
              titleText={item.titleText}
              linkText={item.linkText}
              linkURL={item.linkURL}
            />
          ))}
        </div>
        <img
          src={CarImg}
          alt="car"
          style={{
            position: 'absolute',
            height: '40px',
            top: '560px',
            left: '690px',
            transform: scrollDirection === 'left' ? 'scaleX(-1)' : 'none'
          }}
        />
      </div>

      <div
        style={{
          position: 'relative',
          height: '100px',
          width: '100%',
          paddingTop: '30px'
        }}
      >
        <div className="subContainer" style={{ marginBottom: '100px' }}>
          <div className={styles.line}>
            <div className={styles.dotContainer}>
              <div className={styles.dot} />
            </div>
            <div className={styles.barContainer}>
              <Line
                percent={percent}
                strokeWidth="0.5"
                trailWidth="0.25"
                strokeColor={primaryColor}
                trailColor={lightGreyColor}
              />
            </div>
          </div>
          <div style={{ width: '100%' }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                color: pinkishGreyColor,
                paddingLeft: '4%',
                textTransform: 'uppercase'
              }}
            >
              {milestones.map(item =>
                item.title !== '' ? (
                  <div
                    key={item.id}
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      timelineDesktopRef.current.scrollTo({
                        left: item.left,
                        behavior: 'smooth'
                      });

                      if (item.left > timelineDesktopRef.current.scrollLeft) {
                        setScrollDirection('right');
                      } else {
                        setScrollDirection('left');
                      }
                    }}
                  >
                    {item.title}
                  </div>
                ) : (
                  ''
                )
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  const renderMobile = (
    <div style={{ display: width >= 1200 ? 'none' : 'block', paddingBottom: '50px' }}>
      <div
        style={{
          position: 'relative'
        }}
      >
        <div style={{ marginTop: '50px' }}>{componentTitle}</div>
        <div
          style={{
            position: 'relative',
            marginTop: '30px',
            overflowX: 'scroll'
          }}
          ref={timelineRef}
        >
          <div
            style={{
              position: 'relative',
              height: '500px',
              width: '5900px',
              overflow: 'hidden'
            }}
          >
            <img
              src={TimelineBg}
              alt="timeline-bg"
              style={{
                position: 'absolute',
                height: '500px',
                width: '6490px',
                left: width >= 768 ? '-400px' : '-560px'
              }}
            />
          </div>

          {eventItems.map(item => (
            <EventBubbleMobile
              key={item.id}
              id={item.id}
              top={item.top}
              left={width >= 768 ? item.left - 400 : item.left - 560}
              width={item.width}
              date={item.date}
              titleText={item.titleText}
              linkText={item.linkText}
              linkURL={item.linkURL}
            />
          ))}
        </div>
        <img
          src={CarImg}
          alt="car"
          style={{
            position: 'absolute',
            height: '40px',
            top: '520px',
            left: width >= 768 ? '280px' : '140px',
            transform: scrollDirection === 'left' ? 'scaleX(-1)' : 'none'
          }}
        />
      </div>
      <div
        style={{
          position: 'relative',
          height: '100px',
          width: '100%',
          overflowX: width >= 768 ? 'visible' : 'scroll',
          overflowY: 'hidden'
        }}
      >
        <div className="subContainer" style={{ marginBottom: '100px' }}>
          <div className={styles.line}>
            <div className={styles.dotContainer}>
              <div className={styles.dot} />
            </div>
            <div className={styles.barContainer}>
              <Line
                percent={percent}
                strokeWidth="0.5"
                trailWidth="0.25"
                strokeColor={primaryColor}
                trailColor={lightGreyColor}
              />
            </div>
          </div>
          <div style={{ width: width >= 768 ? '100%' : '500px' }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                color: pinkishGreyColor,
                paddingLeft: '4%',
                textTransform: 'uppercase'
              }}
            >
              {milestones.map(item =>
                item.title !== '' ? (
                  <div
                    key={item.id}
                    onClick={() => {
                      timelineRef.current.scrollTo({
                        left: item.left,
                        behavior: 'smooth'
                      });
                    }}
                  >
                    {item.title}
                  </div>
                ) : (
                  ''
                )
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <>
      {renderDesktop}
      {renderMobile}
    </>
  );
};

export default TimelineContent;
