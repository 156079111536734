import React from 'react';
import SectionTitle from '../UI/SectionTitle';
import styles from './Intro.module.css';
import ColorLogo from '../../assets/img/Color.png';
// import { Countdown } from '../UI/Countdown/Countdown';
import Vehicle from './Vehicle';
import skyline from '../../assets/img/buildings/skyline.png';
import Drone from './Drone';

import Human from './Human';

const speeds = [10, 12, 10, 12];
const carHeight = 30;
const laneLevel = [17, 22, 47, 62];

const vehicles = [
  {
    height: 25,
    bottom: 170,
    start: -1200,
    end: 1600,
    vehicleName: 'Train',
    speed: 7,
    zIndex: 0
  },
  {
    height: carHeight,
    bottom: laneLevel[3],
    start: -100,
    end: 1600,
    vehicleName: 'GreyCar',
    speed: speeds[3],
    delay: 0,
    zIndex: 2
  },
  {
    height: carHeight + 5,
    bottom: laneLevel[2],
    start: -100,
    end: 1600,
    vehicleName: 'BrownLorry',
    speed: speeds[2],
    delay: 2,
    zIndex: 3
  },
  {
    height: carHeight + 10,
    bottom: laneLevel[2],
    start: -100,
    end: 1600,
    vehicleName: 'AutonomousCarBlue',
    speed: speeds[2],
    delay: 3,
    zIndex: 3
  },
  {
    height: carHeight,
    bottom: laneLevel[3],
    start: -100,
    end: 1600,
    vehicleName: 'GreenScooterhuman',
    speed: speeds[3],
    delay: 4,
    zIndex: 2
  },
  {
    height: carHeight,
    bottom: laneLevel[2],
    start: -100,
    end: 1600,
    vehicleName: 'BlueCar',
    speed: speeds[2],
    delay: 7,
    zIndex: 3
  },
  {
    height: carHeight + 15,
    bottom: laneLevel[2],
    start: -100,
    end: 1600,
    vehicleName: 'Bus',
    speed: speeds[2],
    delay: 10,
    zIndex: 3
  },

  {
    height: carHeight,
    bottom: laneLevel[0],
    start: 1600,
    end: -200,
    vehicleName: 'TealBikehuman',
    speed: speeds[0],
    delay: 1,
    zIndex: 5
  },
  {
    height: carHeight,
    bottom: laneLevel[0],
    start: 1600,
    end: -200,
    vehicleName: 'Orangetruck',
    speed: speeds[0],
    delay: 2,
    zIndex: 5
  },
  {
    height: carHeight + 10,
    bottom: laneLevel[1],
    start: 1600,
    end: -200,
    vehicleName: 'AutonomousCarRed',
    speed: speeds[1],
    delay: 3,
    zIndex: 4
  },
  {
    height: carHeight,
    bottom: laneLevel[0],
    start: 1600,
    end: -200,
    vehicleName: 'TealVan',
    speed: speeds[0],
    delay: 4,
    zIndex: 5
  },
  {
    height: carHeight,
    bottom: laneLevel[0],
    start: 1600,
    end: -200,
    vehicleName: 'RedBikehuman',
    speed: speeds[0],
    delay: 5,
    zIndex: 5
  },
  {
    height: carHeight,
    bottom: laneLevel[1],
    start: 1600,
    end: -200,
    vehicleName: 'Taxi',
    speed: speeds[1],
    delay: 6,
    zIndex: 4
  }
];

const drone = [
  {
    id: 1,
    droneName: 'drone1',
    height: 15,
    bottom: 300
  },
  {
    id: 2,
    droneName: 'drone2',
    height: 15,
    bottom: 250
  },
  {
    id: 3,
    droneName: 'drone3',
    height: 15,
    bottom: 350
  }
];

const humans = [
  {
    id: 1,
    start: 30,
    end: 30,
    left: 30,
    zIndex: 4
  },
  {
    id: 2,
    start: 110,
    end: 110,
    left: 110,
    zIndex: 4
  },
  {
    id: 3,
    start: 270,
    end: 270,
    left: 270,
    zIndex: 4
  },
  {
    id: 4,
    start: 325,
    end: 325,
    left: 325,
    zIndex: 4
  },
  {
    id: 5,
    start: 360,
    end: 360,
    left: 360,
    zIndex: 4
  },
  {
    id: 6,
    start: 483,
    end: 483,
    left: 483,
    zIndex: 4
  },
  {
    id: 7,
    start: 520,
    end: 520,
    left: 520,
    zIndex: 4
  },
  {
    id: 8,
    start: 700,
    end: 700,
    left: 700,
    zIndex: 4
  },
  {
    id: 9,
    start: 300,
    end: 1200,
    left: 300,
    zIndex: 4
  },
  {
    id: 10,
    start: 150,
    end: 1200,
    left: 150,
    zIndex: 4
  },
  {
    id: 11,
    start: 100,
    end: -700,
    left: 100,
    zIndex: 4
  },
  {
    id: 12,
    start: 700,
    end: 1200,
    left: 100,
    zIndex: 4
  },
  {
    id: 13,
    start: 350,
    end: 0,
    left: 0,
    zIndex: 4
  }
];

const Intro = () => {
  return (
    <div className={styles.container}>
      <div style={{ position: 'relative' }}>
        <div className={styles.centerContent}>
          <img src={ColorLogo} className={styles.logo} alt="ColorLogo" style={{ height: '90px' }} />
          <SectionTitle title="Reimagining cities" />
          <SectionTitle title="of the future" customStyles={{ marginBottom: '30px' }} />
          {/* <div className={styles.challengeStartsIn}>Entry submission deadline</div>
          <Countdown date="2020/06/30 23:59:59" /> */}
        </div>

        <div
          className={styles.buildingLayer}
          style={{ backgroundImage: `url(${skyline})`, zIndex: 4 }}
        />

        <div className={styles.vehiclesLayer}>
          {drone.map(item => (
            <Drone
              key={`drone${item.id}`}
              droneName={item.droneName}
              height={item.height}
              bottom={item.bottom}
            />
          ))}

          {vehicles.map(item => (
            <Vehicle
              key={item.vehicleName}
              height={item.height}
              bottom={item.bottom}
              start={item.start}
              end={item.end}
              vehicleName={item.vehicleName}
              speed={item.speed}
              delay={item.delay}
              zIndex={item.zIndex}
            />
          ))}

          {humans.map(item => (
            <Human
              key={item.id}
              id={item.id}
              start={item.start}
              end={item.end}
              left={item.left}
              zIndex={item.zIndex}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Intro;
