import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import styles from './RoundModal.module.css';
import { ReactComponent as CloseIcon } from '../../../assets/icons/Cross.svg';

const RoundModal = props => {
  const { showModal, onHide, children, title } = props;

  return (
    <Modal show={showModal} onHide={onHide} size="lg" centered>
      <Modal.Body>
        <div>
          <div className={styles.modalHeader}>
            <div onClick={onHide} style={{ cursor: 'pointer' }}>
              <CloseIcon />
            </div>
          </div>
          {title ? <div className={styles.modalTitle}>{title.toUpperCase()}</div> : null}
          {children}
        </div>
      </Modal.Body>
    </Modal>
  );
};
RoundModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  onHide: PropTypes.func,
  children: PropTypes.node,
  title: PropTypes.string
};
RoundModal.defaultProps = {
  onHide: () => {},
  children: <div />,
  title: null
};

export default RoundModal;
