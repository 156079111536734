import React, { useState } from 'react';
import SectionTitle from '../UI/SectionTitle';
import styles from './ContactUsForm.module.css';
import CircleInput from '../UI/CircleInput/CircleInput';
import RoundTextArea from '../UI/RoundTextArea/RoundTextArea';
import CircleButton from '../UI/CircleButton/CircleButton';
import CircleDropDown from '../UI/CircleDropDown/CircleDropDown';
import { contactFormDropdown } from '../../assets/constant/data';
import { validateEmail } from '../../utils/String.utils';
import { instance } from '../../axios';
import MessageModal from '../UI/MessageModal';
import Paragraph from '../UI/Paragraph';
import Tick from '../../assets/line_icons/Tick_Circle.png';
import Cross from '../../assets/line_icons/Cross_Circle.png';

const grecaptchaObject = window.grecaptcha;

const ContactUsForm = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [reason, setReason] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modalIcon, setModalIcon] = useState(Tick);
  const [modalHeader, setModalHeader] = useState('');
  const [modalContent, setModalContent] = useState('');
  const [modalBtnLabel, setModalBtnLabel] = useState('');

  const clearForm = () => {
    setFirstName('');
    setLastName('');
    setEmail('');
    setMessage('');
    setReason(contactFormDropdown[0].text);
  };
  const showErrorModal = () => {
    setShowModal(true);
    setLoading(false);
    clearForm();
    setModalIcon(Cross);
    setModalHeader('Oh no!');
    setModalContent('Something went wrong. Let’s try again.');
    setModalBtnLabel('try again');
  };

  const showSuccessModal = () => {
    setShowModal(true);
    setLoading(false);
    clearForm();
    setModalIcon(Tick);
    setModalHeader('thank you for getting in touch');
    setModalContent(
      'Your enquiry has been passed on to a member of the team. We will respond to you as soon as possible.'
    );
    setModalBtnLabel('ok');
  };
  const onSubmitClick = () => {
    if (!firstName || !lastName || !email || !validateEmail(email)) {
      console.warn('form is not complete');
      return;
    }
    setLoading(true);
    // console.log('TCL: onSubmitClick -> grecaptchaObject', grecaptchaObject);
    grecaptchaObject.ready(function() {
      grecaptchaObject
        .execute(process.env.REACT_APP_RECAPTCHA_SITE_KEY, { action: 'homepage' })
        .then(function(token) {
          // console.log('TCL: onSubmitClick -> token', token);
          instance
            .post('/email/send-email', { email, firstName, lastName, message, reason, token })
            .then(
              res => {
                const result = res.data.success;
                if (result) {
                  showSuccessModal();
                } else {
                  showErrorModal();
                }
              },
              () => {
                showErrorModal();
              }
            );
        });
    });
  };

  const reasonHandler = text => {
    setReason(text);
  };
  const nameValidationFn = text => {
    return text.length === 0 ? 'The name is empty' : null;
  };
  const emailValidationFn = text => {
    if (text.length === 0) {
      return 'The email address is empty';
    }
    if (!validateEmail(text)) {
      return "The email address you've entered is invalid";
    }
    return null;
  };
  const messageValidationFn = text => {
    return text.length === 0 ? 'The message is empty' : null;
  };
  const handleClose = () => {
    setShowModal(false);
  };
  return (
    <>
      <div className={styles.container}>
        <SectionTitle title="We'd love to hear from you" />
        <div className="subContainer" style={{ marginTop: '24px', marginBottom: '56px' }}>
          <Paragraph>
            Submit your queries here and we will get back to you as soon as possible
          </Paragraph>
        </div>

        <form style={{ width: '100%' }}>
          <div className={['subContainer', styles.formContainer].join(' ')}>
            <div className={styles.nameRow}>
              <div className={[styles.inputContainer, styles.nameContainer].join(' ')}>
                <CircleInput
                  placeHolder="First Name"
                  type="text"
                  value={firstName}
                  onChange={text => setFirstName(text)}
                  validationFn={nameValidationFn}
                />
              </div>
              <div className={[styles.inputContainer, styles.nameContainer].join(' ')}>
                <CircleInput
                  placeHolder="Last Name"
                  type="text"
                  value={lastName}
                  onChange={text => setLastName(text)}
                  validationFn={nameValidationFn}
                />
              </div>
            </div>
            <div className={styles.inputContainer}>
              <CircleInput
                placeHolder="Enter your email here"
                type="email"
                value={email}
                onChange={text => setEmail(text)}
                validationFn={emailValidationFn}
              />
            </div>
            <div className={styles.inputContainer}>
              <RoundTextArea
                placeHolder="Message"
                value={message}
                onChange={text => setMessage(text)}
                validationFn={messageValidationFn}
              />
            </div>
            <div>
              <CircleDropDown
                onSelect={reasonHandler}
                placeholder="Reasons for reaching out"
                listItems={contactFormDropdown}
                errorMessage="Reason is not selected"
              />
            </div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <CircleButton label="send message" onClick={onSubmitClick} isLoading={loading} />
            </div>
          </div>
        </form>
      </div>
      <MessageModal
        showModal={showModal}
        onHide={handleClose}
        icon={modalIcon}
        header={modalHeader}
        content={modalContent}
        btnLabel={modalBtnLabel}
      />
    </>
  );
};

export default ContactUsForm;
