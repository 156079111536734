import PropTypes from 'prop-types';
import React, { useState, useRef } from 'react';
import { greyColor, primaryColor, secondaryColor } from '../../../assets/css/variables';
import { ReactComponent as ArrowUp } from '../../../assets/icons/Dropdown Collapse.svg';
import { ReactComponent as ArrowDown } from '../../../assets/icons/Dropdown Expand.svg';
import styles from './CircleDropDown.module.css';

const areEqual = () => {
  return true;
};

const CircleDropDown = props => {
  const { listItems, placeholder, onSelect, label, errorMessage } = props;
  const [showList, setShowList] = useState('none');
  const [title, setTitle] = useState('');
  const [touch, setTouch] = useState(false);
  const [errMsg, setErrMsg] = useState(null);
  const inputRef = useRef();
  const toggleList = () => {
    if (placeholder === title && showList === 'block') {
      setErrMsg(errorMessage);
    } else {
      setErrMsg(null);
    }
    setTouch(true);
    setShowList(pre => (pre === 'none' ? 'block' : 'none'));
  };
  let headerTitleColor = greyColor;
  if (title !== placeholder) {
    headerTitleColor = secondaryColor;
  }
  if (touch && errMsg) {
    headerTitleColor = primaryColor;
  }
  const onInputClick = evt => {
    if (document.activeElement === inputRef.current) {
      inputRef.current.blur();
      evt.stopPropagation();
    }
  };
  const onListItemClick = item => {
    setShowList('none');
    setTitle(item);
    onSelect(item);
  };
  const headerClass = showList === 'none' ? styles.ddHeader : styles.ddHeaderHalf;
  const ddListClass = showList === 'none' ? styles.ddList : styles.ddListActive;
  return (
    <>
      {label ? <span className={styles.inputLabel}>{label}</span> : null}
      <div className={styles.overlay} onClick={toggleList} style={{ display: showList }} />
      <div className={styles.ddWrapper}>
        <div
          className={headerClass}
          style={touch && errMsg ? { borderColor: primaryColor } : null}
          onClick={toggleList}
        >
          <input
            ref={inputRef}
            className={styles.ddHeaderTitle}
            style={{ color: headerTitleColor }}
            value={title}
            onClick={onInputClick}
            onFocus={toggleList}
            readOnly
          />
          <div>{showList === 'none' ? <ArrowDown /> : <ArrowUp />}</div>
        </div>
        <ul className={ddListClass}>
          {listItems.map(item => (
            <li
              role="option"
              aria-selected="false"
              key={item.value}
              className={styles.ddListItem}
              onClick={() => onListItemClick(item.text)}
            >
              {item.text}
            </li>
          ))}
        </ul>
      </div>
      <div className={styles.errMsg}>{errMsg}</div>
    </>
  );
};
CircleDropDown.propTypes = {
  onSelect: PropTypes.func,
  label: PropTypes.node,
  errorMessage: PropTypes.string,
  placeholder: PropTypes.string,
  listItems: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      value: PropTypes.string
    })
  ).isRequired
};
CircleDropDown.defaultProps = {
  placeholder: '',
  onSelect: () => {},
  label: null,
  errorMessage: 'Please select one option'
};
export default React.memo(CircleDropDown, areEqual);
