import AOS from 'aos';
import 'aos/dist/aos.css';
import React, { useEffect, useRef, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { videoUrl } from '../../assets/constant/data';
import { titleMarginBottom } from '../../assets/css/variables';
// import { instance } from '../../axios';
import CircleButton from '../UI/CircleButton/CircleButton';
import Paragraph from '../UI/Paragraph';
import SectionTitle from '../UI/SectionTitle';
import TextLink from '../UI/TextLink/TextLink';
import styles from './ProblemStatement.module.css';
import PSCard from './PSCard';
import StepCard from './StepCard';

const ProblemStatement = () => {
  const videoEl = useRef(null);
  const history = useHistory();
  const [videoElWidth, setVideoElWidth] = useState(null);
  // const [stepsData, setStepsData] = useState([]);
  const stepsData = [];
  useEffect(() => {
    function handleResize() {
      setVideoElWidth(videoEl.current.offsetWidth);
      window.addEventListener('resize', handleResize);
    }
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  // useEffect(() => {
  //   instance.get('/data/load?name=challengeSteps').then(
  //     res => {
  //       setStepsData(res.data.body);
  //     },
  //     err => {
  //       console.error(err);
  //     }
  //   );
  // }, []);
  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: 'ease-out-back'
    });
  }, []);
  return (
    <div className={styles.container}>
      <SectionTitle title="Problem Statement" customStyles={{ marginBottom: titleMarginBottom }} />
      <div className="subContainer">
        <div data-aos="fade-up" data-aos-anchor-placement="bottom-bottom">
          <PSCard />
        </div>
      </div>
      <div className={['subContainer', styles.videoSection].join(' ')}>
        <div data-aos="fade-up" data-aos-anchor-placement="top-center">
          <div className={styles.iframe} style={{ height: videoElWidth * 0.5 }}>
            <div className={styles.iframeWrapper} style={{ height: videoElWidth * 0.5 }}>
              <iframe
                ref={videoEl}
                style={{ height: videoElWidth * 0.5, width: '100%', border: 0 }}
                title="video"
                frameBorder="0"
                src={videoUrl}
              />
            </div>
          </div>
        </div>
      </div>
      <SectionTitle title="Get Involved" customStyles={{ marginBottom: titleMarginBottom }} />
      <Paragraph>Here’s what you need to do, in 3 simple steps:</Paragraph>
      <Row className={['subContainerForRow', styles.steps].join(' ')}>
        {stepsData.length > 0 &&
          stepsData.map(step => (
            <Col md={6} lg={4} key={step.step.toString()} style={{ marginTop: '16px' }}>
              <StepCard step={step.step} title={step.title} description={step.description} />
            </Col>
          ))}
      </Row>
      <div className={styles.actionBtn}>
        <CircleButton label="join the challenge" onClick={() => history.push('/submit')} />
      </div>
      <div>
        <TextLink href="/#contact">Have questions?</TextLink>
      </div>
    </div>
  );
};

export default ProblemStatement;
