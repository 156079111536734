import React from 'react'; // , { useEffect, useState }

import { useHistory } from 'react-router-dom';
import { ReactComponent as Bulb } from '../../assets/line_icons/Light Bulb.svg';
// import { instance } from '../../axios';
import CircleBanner from '../UI/CircleBanner/CircleBanner';
import RoundDashCard from '../UI/RoundDashCard';
import SectionTitle from '../UI/SectionTitle';
import styles from './Partner.module.css';

const TMF_LOGO_ID = '4a7c0de2-15fc-4594-8f16-25d1b58f7fa0';
const FOM_LOGO_ID = 'ce791b1f-8416-43ab-8488-753572c1f473';

const Partner = () => {
  const history = useHistory();
  // const [partners, setPartners] = useState([]);
  const partners = [];
  // useEffect(() => {
  //   instance.get('/data/load?name=partners').then(
  //     res => {
  //       setPartners(res.data.body);
  //     },
  //     err => {
  //       console.error(err);
  //     }
  //   );
  // }, []);
  return (
    <div className={styles.container}>
      <div className="subContainer">
        <CircleBanner
          Icon={<Bulb height={80} width={80} />}
          onButtonClick={() => history.push('/submit')}
          header="READY TO PRESENT YOUR INNOVATIVE IDEAS?"
          buttonLabel="submit here"
        />
        <SectionTitle
          title="CATCH Partners"
          customStyles={{ marginTop: '48px', marginBottom: '48px' }}
        />
        {partners.length > 0 &&
          partners.map(data => {
            // let logoArrangement = {};
            // switch (true) {
            //   case data.id === '4' || data.id === '5' || data.id === '1':
            //     logoArrangement = { justifyContent: 'space-between' };
            //     break;
            //   default:
            //     logoArrangement = { justifyContent: 'space-evenly' };
            // }
            return (
              <RoundDashCard cardClass={styles.cardContainer} key={data.id}>
                <div className={styles.label}>{data.label.toUpperCase()}</div>
                <div
                  className={data.id === '4' || data.id === '3' ? styles.divider2 : styles.divider}
                />
                <div className={styles.logos}>
                  {data.length > 0 &&
                    data.logos.map(({ id, link, url }) => (
                      <div key={id}>
                        {id === TMF_LOGO_ID && <span>Organised by</span>}
                        {id === FOM_LOGO_ID && <span>Co-developed by</span>}
                        <a href={link} target="_blank" rel="noopener noreferrer">
                          <img src={url} alt="logo" />
                        </a>
                      </div>
                    ))}
                </div>
              </RoundDashCard>
            );
          })}
      </div>
    </div>
  );
};

export default Partner;
