import React, { lazy, Suspense } from 'react';
import { Container } from 'react-bootstrap';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import ReactRouterPropTypes from 'react-router-prop-types';
import './App.css';
import styles from './App.module.css';
import Footer from './components/Footer/Footer';
import NavigationBar from './components/NavigationBar/NavigationBar';
import Handbook from './Pages/Handbook/Handbook';
import MainPageContainer from './Pages/MainPageContainer';
import Welcome from './Pages/Welcome';

const ResourcePage = lazy(() => import('./Pages/Resources/ResourcesPage'));
const SubmitPage = lazy(() => import('./Pages/SubmitForm/SubmitFormPage'));
const TermConditionPage = lazy(() => import('./Pages/TermCondition/TermConditionPage'));
const PrivatePolicyPage = lazy(() => import('./Pages/PrivatePolicy/PrivatePolicyPage'));
const JudgesPage = lazy(() => import('./Pages/Judges/JudgesPage'));
const EventsPage = lazy(() => import('./Pages/Events/EventsPage'));
const ParticipantsPage = lazy(() => import('./Pages/Finalists/ParticipantsPage'));

const App = props => {
  const { history } = props;
  const routes = (
    <Switch>
      <Route path="/" exact component={MainPageContainer} />
      <Route path="/events" component={EventsPage} />
      <Route path="/judges" component={JudgesPage} />
      <Route path="/participants" component={ParticipantsPage} />
      <Route path="/resource" component={ResourcePage} />
      <Route path="/submit" component={SubmitPage} />
      <Route path="/terms-and-conditions" component={TermConditionPage} />
      <Route path="/private-policy" component={PrivatePolicyPage} />
      <Route path="/welcome" component={Welcome} />
      <Route path="/handbook" component={Handbook} />
      <Route path="/welcome" component={Welcome} />
      <Redirect to="/" />
    </Switch>
  );

  return (
    <Container>
      <div>
        <div>
          <NavigationBar history={history} />
        </div>
        <div className={styles.pageContainer}>
          <Suspense fallback={<div style={{ height: '1000px' }} />}>
            <main>{routes}</main>
          </Suspense>
        </div>
        <Footer />
      </div>
    </Container>
  );
};

App.propTypes = {
  history: ReactRouterPropTypes.history.isRequired
};
export default withRouter(App);
