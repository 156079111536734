import React from 'react';
import PropTypes from 'prop-types';
import StylePropType from 'react-style-proptype';
import { borderRadius, borderColor } from '../../assets/css/variables';

const RoundDashCard = props => {
  const { children, style, cardClass } = props;
  return (
    <div
      className={cardClass}
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        border: `2px dashed ${borderColor}`,
        borderRadius,
        backgroundColor: 'white',
        ...style
      }}
    >
      {children}
    </div>
  );
};

RoundDashCard.propTypes = {
  children: PropTypes.node.isRequired,
  style: StylePropType,
  cardClass: PropTypes.string
};
RoundDashCard.defaultProps = {
  style: {},
  cardClass: ''
};

export default RoundDashCard;
