import React from 'react';
import PropTypes from 'prop-types';
import StylePropTypes from 'react-style-proptype';
import styles from './SectionTitle.module.css';

const SectionTitle = props => {
  const { title, customStyles } = props;
  return (
    <div className={styles.title} style={{ ...customStyles }}>
      {title}
    </div>
  );
};
SectionTitle.propTypes = {
  title: PropTypes.string.isRequired,
  customStyles: StylePropTypes
};

SectionTitle.defaultProps = {
  customStyles: {}
};

export default SectionTitle;
