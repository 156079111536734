import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { primaryColor } from '../../assets/css/variables';

import Icon11 from '../../assets/line_icons/City_Planning_1.svg';
import Icon21 from '../../assets/line_icons/Data_1.svg';
import Icon31 from '../../assets/line_icons/Technology_1.svg';

import Icon12 from '../../assets/line_icons/City_Planning_2.svg';
import Icon22 from '../../assets/line_icons/Data_2.svg';
import Icon32 from '../../assets/line_icons/Technology_2.svg';

const Smallicon = props => {
  const { title, callbackFromParent, hoverState, iconId } = props;

  const [isHovering, setIsHovering] = useState(false);

  const toggleHoverState = () => {
    return !isHovering;
  };

  const handleMouseHover = () => {
    setIsHovering(toggleHoverState);
    callbackFromParent(title, toggleHoverState);
  };

  const divBasicStyle = {
    position: 'absolute',
    width: '128px',
    height: '128px',
    border: '2px dashed',
    borderColor: primaryColor,
    borderRadius: '64px',
    backgroundSize: '40%',
    backgroundPosition: 'center 30%',
    backgroundRepeat: 'no-repeat',
    display: 'flex',
    alignItems: 'flex-end',
    paddingBottom: '20px',
    justifyContent: 'center'
  };
  let bgImg = '';
  let onHoverBgImg = '';

  switch (true) {
    case title === 'City Planning':
      bgImg = Icon11;
      onHoverBgImg = Icon12;
      break;
    case title === 'Data':
      bgImg = Icon21;
      onHoverBgImg = Icon22;
      break;
    case title === 'Tech':
      bgImg = Icon31;
      onHoverBgImg = Icon32;
      break;
    default:
      bgImg = '';
      onHoverBgImg = '';
  }

  const divStyle = {
    backgroundColor: '#fff',
    backgroundImage: `url(${bgImg})`
  };

  const divHoverStyle = {
    opacity: 1,
    transition: '1s',
    color: 'white',
    backgroundColor: primaryColor,
    backgroundImage: `url(${onHoverBgImg})`
  };

  return (
    <div
      style={
        isHovering || hoverState
          ? { ...divHoverStyle, ...divBasicStyle }
          : { ...divStyle, ...divBasicStyle }
      }
      className={iconId}
      onMouseEnter={handleMouseHover}
      onMouseLeave={handleMouseHover}
    >
      <div
        id={iconId}
        style={{
          fontFamily: 'helvetica',
          fontSize: '12px',
          fontWeight: 'bold',
          lineHeight: 1.17,
          letterSpacing: '0.92px',
          textAlign: 'center',
          width: '70%'
        }}
      >
        {title.toUpperCase()}
      </div>
    </div>
  );
};

Smallicon.propTypes = {
  iconId: PropTypes.string.isRequired,
  title: PropTypes.string,
  callbackFromParent: PropTypes.func,
  hoverState: PropTypes.bool
};

Smallicon.defaultProps = {
  title: '',
  callbackFromParent: null,
  hoverState: false
};

export default Smallicon;
