import React, { useRef, useEffect } from 'react';
import { TweenMax, Linear } from 'gsap';
import PropTypes from 'prop-types';
import { Transition } from 'react-transition-group';

import h1 from '../../assets/img/humans/human1.png';
import h2 from '../../assets/img/humans/human2.png';
import h3 from '../../assets/img/humans/human3.png';
import h4 from '../../assets/img/humans/human4.png';
import h5 from '../../assets/img/humans/human5.png';
import h6 from '../../assets/img/humans/human6.png';
import h7 from '../../assets/img/humans/human7.png';
import h8 from '../../assets/img/humans/human8.png';
import hb from '../../assets/img/humans/human-bicycle.png';
import hs1 from '../../assets/img/humans/human-scooter1.png';
import hs2 from '../../assets/img/humans/human-scooter2.png';
import hs3 from '../../assets/img/humans/human-scooter3.png';
import hw from '../../assets/img/humans/human-wheelchair.png';

const Human = props => {
  // const [runDuration, setRunDuration] = useState(1);
  const { id, start, end, left, zIndex } = props;

  let humanImg = '';
  switch (true) {
    case id === 1:
      humanImg = h1;
      break;
    case id === 2:
      humanImg = h2;
      break;
    case id === 3:
      humanImg = h3;
      break;
    case id === 4:
      humanImg = h4;
      break;
    case id === 5:
      humanImg = h5;
      break;
    case id === 6:
      humanImg = h6;
      break;
    case id === 7:
      humanImg = h7;
      break;
    case id === 8:
      humanImg = h8;
      break;
    case id === 9:
      humanImg = hb;
      break;
    case id === 10:
      humanImg = hs1;
      break;
    case id === 11:
      humanImg = hs2;
      break;
    case id === 12:
      humanImg = hs3;
      break;
    case id === 13:
      humanImg = hw;
      break;

    default:
      humanImg = '';
  }

  // const transformScale = start - end <= 0 ? 'scaleX(1)' : 'scaleX(-1)';

  let carItem = useRef(null);

  const runcars = () => {
    // const runDuration = speed !== 0 ? speed : Math.floor(Math.random() * 10) + 6;
    const runDuration = id === 13 ? 70 : 35;

    TweenMax.fromTo(
      carItem,
      runDuration,
      { x: start },
      {
        x: end,
        ease: Linear.easeNone,
        repeat: -1
      }
    );
  };

  const initFunctions = () => {
    runcars();
  };

  useEffect(initFunctions, []);
  return (
    <Transition in timeout={10000} unmountOnExit>
      <img
        ref={el => {
          carItem = el;
        }}
        src={humanImg}
        alt="human"
        style={{
          height: '30px',
          left,
          position: 'absolute',
          bottom: '70px',
          zIndex
        }}
      />
    </Transition>
  );
};
Human.propTypes = {
  id: PropTypes.number,
  start: PropTypes.number,
  end: PropTypes.number,
  left: PropTypes.number,
  zIndex: PropTypes.number
};

Human.defaultProps = {
  id: 0,
  start: 50,
  end: 700,
  left: 0,
  zIndex: 0
};
export default Human;
