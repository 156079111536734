import React, { useState } from 'react';
import { organiserData, TMFDetailData } from '../../assets/constant/data';
import Paragraph from '../UI/Paragraph';
import RoundModal from '../UI/RoundModal/RoundModal';
import SectionTitle from '../UI/SectionTitle';
import UpperCaseTitle from '../UI/UpperCaseTitle';
import UpperCaseTitleLight from '../UI/UpperCaseTitleLight';
import styles from './Organiser.module.css';
import OrganiserCard from './OrganiserCard';

const getModalBody = () => {
  const data = TMFDetailData;
  return (
    <div className={styles.modalContainer}>
      <UpperCaseTitle>{data.title}</UpperCaseTitle>
      {data.subTitle ? (
        <Paragraph style={{ margin: '20px 0 30px 0' }}>{data.subTitle}</Paragraph>
      ) : null}
      {data.length > 0 &&
        data.concepts.map(con => (
          <div className={styles.modalMain} key={con.title}>
            <div className={styles.modalLogo}>
              <img src={con.logo} alt="concept_logo" height={96} width={96} />
            </div>
            <div className={styles.modalContent}>
              <UpperCaseTitleLight>{con.title}</UpperCaseTitleLight>
              <ul>
                {con.length > 0 && con.points.map(point => <li key={Math.random()}>{point}</li>)}
              </ul>
            </div>
          </div>
        ))}
    </div>
  );
};

const Organiser = () => {
  const [showModal, setShowModal] = useState(false);
  const [selectedOrganiser, setSelectedOrganiser] = useState('TMF');

  const onLearnMoreClick = name => {
    setSelectedOrganiser(name);
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const modalBody = getModalBody(selectedOrganiser);

  return (
    <div className={styles.container}>
      <SectionTitle title="The Organiser" />
      <div className={['subContainer', styles.cardsRow].join(' ')}>
        {organiserData.map(data => (
          <div key={data.id}>
            <OrganiserCard
              logoUrl={data.logoUrl}
              description={data.description}
              url={data.url}
              orgId={data.id}
              learnMore={() => onLearnMoreClick(data.name)}
            />
          </div>
        ))}
      </div>
      <RoundModal showModal={showModal} onHide={handleClose}>
        {modalBody}
      </RoundModal>
    </div>
  );
};

export default Organiser;
