import React from 'react'; // , { useState }
import { Link } from 'react-router-dom';
import styles from './Footer.module.css';
// import { validateEmail } from '../../utils/String.utils';
// import { instance } from '../../axios';
// import CircleInput from '../UI/CircleInput/CircleInput';
// import Tick from '../../assets/line_icons/Tick_Circle.png';
// import Cross from '../../assets/line_icons/Cross_Circle.png';
// import { ReactComponent as BtnIcon } from '../../assets/icons/Enter.svg';
import { ReactComponent as FbIcon } from '../../assets/icons/FB.svg';
import { ReactComponent as InsIcon } from '../../assets/icons/IG.svg';
import { ReactComponent as TwIcon } from '../../assets/icons/Twitter.svg';
import { ReactComponent as LIIcon } from '../../assets/icons/LinkedIn.svg';
// import MessageModal from '../UI/MessageModal';

function Footer() {
  // const [email, setEmail] = useState('');
  // const [showModal, setShowModal] = useState(false);
  // const [loading, setLoading] = useState(false);
  // const [modalIcon, setModalIcon] = useState(Tick);
  // const [modalHeader, setModalHeader] = useState('');
  // const [modalContent, setModalContent] = useState('');
  // const [modalBtnLabel, setModalBtnLabel] = useState('');

  // const showErrorModal = () => {
  //   // setLoading(false);
  //   setShowModal(true);
  //   setEmail('');
  //   setModalIcon(Cross);
  //   setModalHeader('Oh no!');
  //   setModalContent('Something went wrong. Let’s try again.');
  //   setModalBtnLabel('try again');
  // };

  // const showSuccessModal = () => {
  //   // setLoading(false);
  //   setShowModal(true);
  //   setEmail('');
  //   setModalIcon(Tick);
  //   setModalHeader('thank you for subscribing');
  //   setModalContent(
  //     'You will be the first to know about the challenge progress, winners & special projects. Stay tuned!'
  //   );
  //   setModalBtnLabel('ok');
  // };

  // const onSubscribeClick = () => {
  //   if (validateEmail(email)) {
  //     setLoading(true);
  //     instance.post('/mail-chimp/add-subscriber', { email }).then(
  //       () => {
  //         showSuccessModal();
  //       },
  //       () => {
  //         showErrorModal();
  //       }
  //     );
  //   } else {
  //     console.warn('email is not valid');
  //     showErrorModal();
  //   }
  // };
  // const BtnComp = <BtnIcon onClick={onSubscribeClick} />;
  // const handleClose = () => {
  //   setShowModal(false);
  // };
  const onFbClick = () => {
    window.open('https://www.facebook.com/toyotamobilityfoundation/', '_blank');
  };
  const onInsClick = () => {
    window.open('https://www.instagram.com/toyotamobilityfoundation/?hl=en', '_blank');
  };
  const onTwClick = () => {
    window.open('https://twitter.com/ToyotaMobFdn', '_blank');
  };
  const onLiClick = () => {
    window.open('https://www.linkedin.com/company/toyota-mobility-foundation/', '_blank');
  };
  return (
    <>
      <div style={{ paddingTop: '64px' }} id="subscribe-to-newsletter" />
      <div className={styles.flexRow}>
        {/* <div className={styles.newsLetterSection}>
          <div className={styles.innerContainer}>
            <div className={styles.title}>SUBSCRIBE TO OUR NEWSLETTER</div>
            <div className={styles.subTitle}>
              Receive email updates on the challenge and upcoming events
            </div>
            <div className={styles.content}>
              <CircleInput
                placeHolder="Enter your email here"
                value={email}
                onChange={text => setEmail(text)}
                type="text"
                Btn={BtnComp}
                isBtnLoading={loading}
              />
            </div>
          </div>
        </div> */}
        <div className={styles.socialSection}>
          <div className={styles.innerContainer}>
            <div className={styles.title}>FOLLOW US</div>
            <div className={styles.subTitle}>
              Keep up to date with the latest news and happenings
            </div>
            <div className={styles.content}>
              <div className={styles.iconContainer}>
                <div onClick={onFbClick} className={styles.iconHolder}>
                  <FbIcon height={48} width={48} />
                </div>
                <div
                  onClick={onInsClick}
                  className={styles.iconHolder}
                  style={{ marginLeft: '20px', marginRight: '20px' }}
                >
                  <InsIcon height={48} width={48} />
                </div>
                <div
                  onClick={onTwClick}
                  className={styles.iconHolder}
                  style={{ marginRight: '20px' }}
                >
                  <TwIcon height={48} width={48} />
                </div>
                <div onClick={onLiClick} className={styles.iconHolder}>
                  <LIIcon height={48} width={48} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={`${styles.lowerSection} ${styles.flexRow}`}>
        <div className={styles.linksRow}>
          <div className={styles.link}>
            <Link to="/terms-and-conditions">Terms & Conditions</Link>
          </div>
          <div className={styles.link}>
            <Link to="/private-policy">Privacy & Cookie Policy</Link>
          </div>
        </div>
        <div className={styles.copyRight}>@2020 CATCH. All Rights Reserved.</div>
      </div>
      {/* <MessageModal
        showModal={showModal}
        onHide={handleClose}
        // icon={modalIcon}
        // header={modalHeader}
        // content={modalContent}
        // btnLabel={modalBtnLabel}
      /> */}
    </>
  );
}

export default Footer;
