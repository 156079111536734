import PropTypes from 'prop-types';
import React from 'react';
import BeatLoader from 'react-spinners/BeatLoader';
import styles from './CircleButton.module.css';

const CircleButton = props => {
  const { label, secondary, onClick, isLoading, disable } = props;
  let btnClass = styles.primaryButton;
  if (isLoading && secondary) {
    btnClass = styles.secondaryButtonLoading;
  } else if (secondary) {
    btnClass = styles.secondaryButton;
  } else if (isLoading) {
    btnClass = styles.primaryButtonLoading;
  }
  if (disable && secondary) {
    btnClass = styles.secondaryButtonDisable;
  } else if (disable) {
    btnClass = styles.primaryButtonDisable;
  }
  const clickHandler = isLoading || disable ? () => {} : onClick;
  return (
    <div className={[btnClass, styles.basicBtn].join(' ')} onClick={clickHandler}>
      <span style={{ visibility: isLoading ? 'hidden' : null }}>{label.toUpperCase()}</span>
      {isLoading ? (
        <span style={{ position: 'absolute', margin: 'auto', left: 0, right: 0 }}>
          <BeatLoader sizeUnit="px" size={12} color="white" loading />
        </span>
      ) : null}
    </div>
  );
};
CircleButton.propTypes = {
  label: PropTypes.string.isRequired,
  secondary: PropTypes.bool,
  onClick: PropTypes.func,
  isLoading: PropTypes.bool,
  disable: PropTypes.bool
};
CircleButton.defaultProps = {
  secondary: false,
  isLoading: false,
  onClick: () => {},
  disable: false
};

export default CircleButton;
