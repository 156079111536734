import React from 'react';
import PropTypes from 'prop-types';
import './CircleBadge.css';

const CircleBadge = props => {
  const { radius, bgColor, text, textColor, id } = props;
  // const width = radius * 2;
  return (
    <div
      id={id}
      className="CircleBadgeContainer"
      style={{
        backgroundColor: bgColor,
        color: textColor,
        borderRadius: `${radius}px`
      }}
    >
      {text}
    </div>
  );
};

CircleBadge.propTypes = {
  radius: PropTypes.number,
  id: PropTypes.string,
  bgColor: PropTypes.string,
  text: PropTypes.instanceOf(Array),
  textColor: PropTypes.string
};

CircleBadge.defaultProps = {
  radius: 0,
  id: '',
  bgColor: '',
  text: '',
  textColor: '#fff'
};

export default CircleBadge;
