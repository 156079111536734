import React from 'react'; // , { useEffect, useState }
import Slider from 'react-slick';
import PropTypes from 'prop-types';
import StylePropType from 'react-style-proptype';
import leftArrow from '../../assets/img/news/left-arrow.png';
import rightArrow from '../../assets/img/news/right-arrow.png';
// import { instance } from '../../axios';
import SectionTitle from '../UI/SectionTitle';
import styles from './News.module.css';
import NewsCard from './NewsCard';

const settings = {
  dots: false,
  slidesToShow: 3,
  slidesToScroll: 1,
  infinite: true,
  nextArrow: <SampleNextArrow />,
  prevArrow: <SamplePrevArrow />,
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: true,
        dots: true,
        arrows: false
      }
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        dots: true,
        arrows: false
      }
    }
  ]
};

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <img
      src={rightArrow}
      alt="right arrow"
      className={className}
      style={{ ...style, display: 'block' }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <img
      src={leftArrow}
      alt="left arrow"
      className={className}
      style={{ ...style, display: 'block' }}
      onClick={onClick}
    />
  );
}

const News = () => {
  // const [news, setNews] = useState([]);
  const news = [];
  // useEffect(() => {
  //   instance.get('/data/load?name=news').then(
  //     res => {
  //       const newsArray = [];
  //       for (let i = 0; i < res.data.body.length; i += 1) {
  //         res.data.body[i].id = i + 1;
  //         newsArray.push(res.data.body[i]);
  //       }

  //       setNews(newsArray);
  //     },
  //     err => {
  //       console.error(err);
  //     }
  //   );
  // }, []);
  return (
    <div className={['subContainer', styles.container].join(' ')}>
      <SectionTitle title="Newsroom" />
      <div style={{ padding: '0', maxWidth: '100%', margin: '40px auto 0 auto' }}>
        <Slider {...settings}>
          {news.length > 0 &&
            news.map(item => (
              <div key={item.id}>
                <NewsCard
                  imgURL={item.image}
                  date={item.date}
                  author={item.author}
                  title={item.title}
                  url={item.url}
                />
              </div>
            ))}
        </Slider>
      </div>
    </div>
  );
};
SampleNextArrow.propTypes = {
  className: PropTypes.string,
  style: StylePropType,
  onClick: PropTypes.func
};
SampleNextArrow.defaultProps = {
  className: null,
  style: null,
  onClick: () => {}
};
SamplePrevArrow.propTypes = {
  className: PropTypes.string,
  style: StylePropType,
  onClick: PropTypes.func
};
SamplePrevArrow.defaultProps = {
  className: null,
  style: null,
  onClick: () => {}
};
export default News;
