import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styles from './RoundTextArea.module.css';
import { ReactComponent as Error } from '../../../assets/icons/Error.svg';

const areEqual = (prevProps, nextProps) => {
  return prevProps.value === nextProps.value;
};

const RoundTextArea = props => {
  const { placeHolder, rows, value, onChange, validationFn, label } = props;
  const [touch, setTouch] = useState(false);
  const [errMsg, setErrMsg] = useState(null);
  const onBlurHandler = event => {
    setTouch(true);
    setErrMsg(validationFn(event.target.value));
  };
  const onChangeHandler = event => {
    setErrMsg(null);
    onChange(event.target.value);
  };
  const containerClass = touch && errMsg ? styles.inputContainerError : styles.inputContainer;
  return (
    <>
      {label ? <span className={styles.inputLabel}>{label}</span> : null}
      <div className={containerClass}>
        <textarea
          value={value}
          onChange={onChangeHandler}
          onBlur={onBlurHandler}
          className={styles.input}
          placeholder={placeHolder}
          rows={rows}
        />
        {touch && errMsg ? (
          <div className={styles.errorIcon}>
            <Error height={24} width={24} />
          </div>
        ) : null}
      </div>
      <div className={styles.errMsg}>{errMsg}</div>
    </>
  );
};
RoundTextArea.propTypes = {
  label: PropTypes.string,
  placeHolder: PropTypes.string,
  rows: PropTypes.number,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  validationFn: PropTypes.func
};
RoundTextArea.defaultProps = {
  label: null,
  placeHolder: '',
  rows: 4,
  validationFn: () => null,
  value: ''
};

export default React.memo(RoundTextArea, areEqual);
