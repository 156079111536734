import React from 'react';
import PropTypes from 'prop-types';
import StylePropType from 'react-style-proptype';
import styles from './TextLink.module.css';

const TextLink = props => {
  const { style, children, href, target } = props;
  return (
    <a href={href} target={target} className={styles.link} style={style}>
      {children}
    </a>
  );
};
TextLink.propTypes = {
  children: PropTypes.node.isRequired,
  style: StylePropType,
  href: PropTypes.string.isRequired,
  target: PropTypes.string
};
TextLink.defaultProps = {
  style: {},
  target: '_self'
};
export default TextLink;
