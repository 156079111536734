import React from 'react';
import { Row } from 'react-bootstrap';
import UpperCaseTitle from '../UI/UpperCaseTitle';
import Paragraph from '../UI/Paragraph';
import TextLink from '../UI/TextLink/TextLink';
import { ReactComponent as Goal7 } from '../../assets/img/goals/7.svg';
import { ReactComponent as Goal8 } from '../../assets/img/goals/8.svg';
import { ReactComponent as Goal9 } from '../../assets/img/goals/9.svg';
import { ReactComponent as Goal10 } from '../../assets/img/goals/10.svg';
import { ReactComponent as Goal11 } from '../../assets/img/goals/11.svg';
import { ReactComponent as Goal17 } from '../../assets/img/goals/17.svg';
import RoundDashCard from '../UI/RoundDashCard';
import useResponsiveStyle from '../../hooks/useResponsiveStyle';

const defaultStyles = [
  {
    mobile: { padding: '18px' },
    tablet: { padding: '30px' },
    desktop: { padding: '40px' }
  },
  {
    mobile: { height: '88px', width: '88px' },
    tablet: { height: '100px', width: '100px' },
    desktop: { height: '140px', width: '140px' }
  },
  {
    mobile: { flexBasis: `${100 / 3}%`, textAlign: 'center', marginBottom: '10px' },
    tablet: { flexBasis: `${100 / 6}%`, textAlign: 'center', marginBottom: '10px' },
    desktop: { flexBasis: `${100 / 6}%`, textAlign: 'center', marginBottom: '10px' }
  },
  {
    mobile: { display: 'block' },
    tablet: { display: 'none' },
    desktop: { display: 'none' }
  },
  {
    mobile: { display: 'none' },
    tablet: { display: 'block' },
    desktop: { display: 'block' }
  }
];

const icons = [Goal7, Goal8, Goal9, Goal10, Goal11, Goal17];

const ChallengeTheme = () => {
  const [containerStyles, iconStyles, goalStyles, showMobile, hideMobile] = useResponsiveStyle(
    defaultStyles
  );

  return (
    <div>
      <RoundDashCard style={containerStyles}>
        <UpperCaseTitle>
          United nations&nbsp;
          <br style={hideMobile} />
          sustainable&nbsp;
          <br style={showMobile} />
          development goals
        </UpperCaseTitle>
        <Paragraph style={{ margin: '10px 0' }}>
          The entire challenge aims to address 6 of the 17 goals:
        </Paragraph>
        <Row style={{ margin: '25px 0 40px', width: '100%' }}>
          {icons.length > 0 &&
            icons.map(Icon => (
              <div style={goalStyles} key={Math.random()}>
                <Icon style={iconStyles} />
              </div>
            ))}
        </Row>
        <Paragraph style={{ marginBottom: 0 }}>
          Find out more about the
          <br style={showMobile} />
          <TextLink
            href="https://www.un.org/sustainabledevelopment/sustainable-development-goals/"
            target="_blank"
            style={{ marginLeft: '4px' }}
          >
            UN Sustainable Development Goals
          </TextLink>
        </Paragraph>
      </RoundDashCard>
    </div>
  );
};

export default ChallengeTheme;
