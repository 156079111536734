import React from 'react';
import Intro from '../components/IntroWithTimer/Intro';

const Welcome = () => {
  return (
    <div>
      <Intro />
    </div>
  );
};

export default Welcome;
