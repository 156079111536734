import PropTypes from 'prop-types';
import React from 'react';
import CircleButton from '../CircleButton/CircleButton';
import styles from './CircleBanner.module.css';
import { shadow } from '../../../assets/css/variables';

const CircleBanner = props => {
  const { Icon, header, buttonLabel, onButtonClick } = props;
  return (
    <div className={styles.banner} style={{ boxShadow: shadow }}>
      <div className={styles.leftBanner}>
        {Icon}
        <div>{header.toUpperCase()}</div>
      </div>
      <div className={styles.rightBanner}>
        <CircleButton label={buttonLabel} onClick={onButtonClick} />
      </div>
    </div>
  );
};
CircleBanner.propTypes = {
  Icon: PropTypes.node.isRequired,
  header: PropTypes.string.isRequired,
  buttonLabel: PropTypes.string.isRequired,
  onButtonClick: PropTypes.func.isRequired
};

export default CircleBanner;
