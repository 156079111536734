import React from 'react';
import PropTypes from 'prop-types';
import StylePropTypes from 'react-style-proptype';
import { primaryColor } from '../../assets/css/variables';

const UpperCaseTitleLight = props => {
  const { children, style, size, className } = props;
  return (
    <div
      style={{
        fontSize: size,
        fontFamily: 'helvetica',
        lineHeight: 1.5,
        letterSpacing: '1px',
        fontStyle: 'normal',
        color: primaryColor,
        ...style
      }}
      className={className}
    >
      {children.toUpperCase()}
    </div>
  );
};
UpperCaseTitleLight.propTypes = {
  children: PropTypes.node.isRequired,
  size: PropTypes.string,
  style: StylePropTypes,
  className: PropTypes.string
};

UpperCaseTitleLight.defaultProps = {
  style: {},
  size: '1.125rem',
  className: ''
};

export default UpperCaseTitleLight;
