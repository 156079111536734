import React from 'react';
import PropTypes from 'prop-types';
import StylePropType from 'react-style-proptype';
import { secondaryColor } from '../../assets/css/variables';

const Paragraph = props => {
  const { children, style, textAlign, mBottom, pClass } = props;
  return (
    <p
      style={{
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.5,
        letterSpacing: 'normal',
        textAlign,
        color: secondaryColor,
        marginBottom: mBottom,
        ...style
      }}
      className={pClass}
    >
      {children}
    </p>
  );
};
Paragraph.propTypes = {
  children: PropTypes.node.isRequired,
  style: StylePropType,
  pClass: PropTypes.string,
  textAlign: PropTypes.string,
  mBottom: PropTypes.string
};
Paragraph.defaultProps = {
  style: {},
  textAlign: 'center',
  mBottom: '0',
  pClass: ''
};
export default Paragraph;
