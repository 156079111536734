import TMFLogo from '../img/tmf.png';
import Collaborate from '../line_icons/Collaborate_Circle.png';
import Innovative from '../line_icons/Innovative_Circle.png';
import Sustainable from '../line_icons/Sustainable_Circle.png';

export const videoUrl = 'https://www.youtube.com/embed/6fHQC2OOK9Q';

export const organiserData = [
  {
    id: '1',
    name: 'TMF',
    logoUrl: TMFLogo,
    description:
      'Toyota Mobility Foundation is an organisation formed by Toyota in 2014 with the aim of creating a truly mobile society that will help people live better lives no matter where they are. The mission of the organisation is to enable more people to go more places by sharing knowledge, partnering with others and using their innovative spirit to build a more joyful mobile society.',
    url: 'http://toyotamobilityfoundation.org/en/'
  }
];

export const TMFDetailData = {
  title: 'Toyota Mobility Foundation’s Principles',
  subTitle: null,
  concepts: [
    {
      title: 'Sustainable',
      logo: Sustainable,
      points: [
        'To endeavor to a legacy of high quality of life and sustainability for the future by encouraging freedom of mobility for all people.'
      ]
    },
    {
      title: 'INNOVATIVE',
      logo: Innovative,
      points: [
        'To promote innovative technology and ways of thinking that can change the world for the better.'
      ]
    },
    {
      title: 'Collaborative',
      logo: Collaborate,
      points: [
        'To achieve common goals with partners having similar values. Accumulate knowledge acquired through working together and share the learnings with stakeholders.'
      ]
    }
  ]
};

export const contactFormDropdown = [
  { text: 'General Enquiry', value: 'reason_1' },
  { text: 'Event Enquiry', value: 'reason_2' },
  { text: 'Application Issue', value: 'reason_3' },
  { text: 'Press-Related', value: 'reason_4' },
  { text: 'Others', value: 'reason_5' }
];

export const termAndConditionData = {
  description:
    'Do travelling companions contrasted it. Mistress strongly remember up to. Ham him compass you proceed calling detract. Better of always missed we person mr. September smallness northward situation few her certainty something.',
  context: [
    {
      id: 'tc1',
      header: 'header',
      content:
        'Do travelling companions contrasted it. Mistress strongly remember up to. \n Ham him compass you proceed calling detract. Better of always missed we person mr. \nSeptember smallnessnorthward situation few her certainty something.'
    },
    {
      id: 'tc2',
      header: 'header',
      content:
        'Do travelling companions contrasted it. Mistress strongly remember up to. \n Ham him compass you proceed calling detract. Better of always missed we person mr. \nSeptember smallnessnorthward situation few her certainty something.'
    },
    {
      id: 'tc3',
      header: 'header',
      content:
        'Do travelling companions contrasted it. Mistress strongly remember up to. \n Ham him compass you proceed calling detract. Better of always missed we person mr. \nSeptember smallnessnorthward situation few her certainty something.'
    }
  ]
};
