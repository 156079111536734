import React, { useRef, useEffect } from 'react';
import { gsap } from 'gsap';
import PropTypes from 'prop-types';
import { Transition } from 'react-transition-group';
import { MotionPathPlugin } from 'gsap/MotionPathPlugin';

import Drone1 from '../../assets/img/vehicles/Drone1.svg';
import Drone2 from '../../assets/img/vehicles/Drone2.svg';
import Drone3 from '../../assets/img/vehicles/Drone3.svg';

gsap.registerPlugin(MotionPathPlugin);

const Drone = props => {
  const { droneName, height, bottom } = props;
  // const path1 =
  //   'M12.6,-250c49.24385,-47.828269999999975,189.53036,-98.94040999999999,258.00001,-94s142.23194,92.40105999999997,192,78s79.57582000000002,-108.78481,117.99996999999996,-130s216.47738000000004,-42.38480000000001,266,2s73.74539000000004,84.34519,64,116s-22.530399999999986,35.676409999999976,-32,46s-248.91063999999994,49.370510000000024,-308,0s-90.27136999999993,-109.03875,-83.99996999999996,-152s199.98505,-50.64008999999999,267.99996999999996,-48s221.40700000000004,21.81065000000001,270,72s30.627660000000105,61.75486999999998,50,72s99.20063000000005,-82.81173999999999,140,-84s115.03285000000005,114.19934999999998,154,92s53.07874000000015,-47.16600999999997,52,-92';
  // const path2 =
  //   'M-47,-250c63.88857999999999,69.97964000000002,106.10318000000001,-161.38122,160,-162s74.13992000000002,179.5197,130,182s83.89764000000002,-184.60486,142,-184s85.34316000000001,177.67523999999997,140,180s109.96234000000004,-187.34508,174,-188s115.76234,182.53635000000003,178,176s86.83434,-175.11252,144,-178s97.85937999999987,196.27649000000002,158,186s63.70489999999995,-198.77819,152,-194s49.422540000000026,244.15640000000002,154,182';
  const path3 = [
    { x: 0, y: 0 },
    { x: 30, y: -30 },
    { x: 0, y: -60 },
    { x: -30, y: -30 },
    { x: 0, y: 0 }
  ];
  const path2 = [
    { x: 0, y: 0 },
    { x: -10, y: 10 },
    { x: -20, y: 0 },
    { x: 0, y: 0 }
  ];
  const path1 = [
    { x: 700, y: -200 },
    { x: 1430, y: 0 }
  ];
  let dronePath;
  let droneImg;
  let left = 0;
  let droneSpeed = 15;
  switch (true) {
    case droneName === 'drone1':
      dronePath = path1;
      droneImg = Drone1;
      left = -10;
      break;
    case droneName === 'drone2':
      dronePath = path2;
      droneImg = Drone2;
      left = 300;
      droneSpeed = 5;
      break;
    case droneName === 'drone3':
      dronePath = path3;
      droneImg = Drone3;
      left = 940;
      droneSpeed = 10;
      break;
    default:
      left = 0;
  }

  let droneItem = useRef(null);

  const runDrones = () => {
    gsap.to(droneItem, droneSpeed, {
      motionPath: {
        path: dronePath
      },
      // ease: gsap.easeNone,
      repeat: -1
    });
  };

  useEffect(runDrones, []);

  return (
    <Transition in timeout={10000} unmountOnExit>
      <img
        ref={el => {
          droneItem = el;
        }}
        src={droneImg}
        alt="drone"
        style={{
          height: `${height}px`,
          left: `${left}px`,
          position: 'absolute',
          bottom: `${bottom}px`,
          zIndex: 10
        }}
      />
    </Transition>
  );
};
Drone.propTypes = {
  droneName: PropTypes.string,
  height: PropTypes.number,
  bottom: PropTypes.number
};

Drone.defaultProps = {
  droneName: '',
  height: 0,
  bottom: 0
};
export default Drone;
