export const borderRadius = '12px';
export const subContainerWidth = '85%';

export const primaryColor = '#E41E2A';
export const secondaryColor = '#3D3D3D';
export const blackColor = '#3d3d3d';
export const greyColor = '#abaeb1';
export const lightGreyColor = '#f5f5f5';
export const pinkishGreyColor = '#cfcfcf';
export const blueColor = '#6EB2BD';
export const borderColor = '#cfcfcf';
export const shadow = '16px 16px 54px rgba(61,61,61,0.4)';
export const titleMarginBottom = '15px';
