import axios from 'axios';

//* API Gateway Axios
const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL
});

instance.CancelToken = axios.CancelToken;
instance.isCancel = axios.isCancel;

instance.interceptors.request.use(request => {
  return request;
});

export { instance };
