import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import ReactRouterPropTypes from 'react-router-prop-types';
import { NavHashLink } from 'react-router-hash-link';
import logo from '../../assets/img/catch_logo.png';
import CircleButton from '../UI/CircleButton/CircleButton';
import styles from './NavigationBar.module.css';
import { primaryColor } from '../../assets/css/variables';
import { ReactComponent as Menu } from '../../assets/icons/Menu.svg';
import { ReactComponent as Close } from '../../assets/icons/Cross_Red.svg';

const links = [
  { url: '/#challenge', label: 'THE CHALLENGE', type: 'hash' },
  { url: '/#timeline', label: 'TIMELINE', type: 'hash' },
  // { url: '/finalists', label: 'FINALISTS', type: 'link' },
  { url: '/participants', label: 'PARTICIPANTS', type: 'link' },
  { url: '/judges', label: 'JUDGES', type: 'link' },
  { url: '/resource', label: 'RESOURCES', type: 'link' },
  { url: '/#contact', label: 'CONTACT US', type: 'hash' }
];

const NavigationBar = props => {
  const { history } = props;
  const [menuOpen, setMenuOpen] = useState(false);
  const LinkComponents = links.map(link => {
    if (link.type === 'link') {
      return (
        <li className={styles.NavigationItem} key={link.url} onClick={() => setMenuOpen(false)}>
          <NavLink
            exact
            to={link.url}
            activeStyle={{
              color: primaryColor
            }}
          >
            {link.label}
          </NavLink>
        </li>
      );
    }
    return (
      <li className={styles.NavigationItem} key={link.url} onClick={() => setMenuOpen(false)}>
        <NavHashLink to={link.url}>{link.label}</NavHashLink>
      </li>
    );
  });
  const LinkDDComponents = links.map(link => {
    if (link.type === 'link') {
      return (
        <li className={styles.NavigationItemDD} key={link.url} onClick={() => setMenuOpen(false)}>
          <NavLink
            exact
            to={link.url}
            activeStyle={{
              color: primaryColor
            }}
          >
            {link.label}
          </NavLink>
        </li>
      );
    }
    return (
      <li className={styles.NavigationItemDD} key={link.url} onClick={() => setMenuOpen(false)}>
        <NavHashLink to={link.url}>{link.label}</NavHashLink>
      </li>
    );
  });
  return (
    <>
      <div className={[styles.NavigationBar].join(' ')}>
        <a href="/">
          <img src={logo} className={styles.logo} alt="ColorLogo" />
        </a>
        <ul className={styles.NavigationItems}>{LinkComponents}</ul>
        <div className={styles.RightButtons}>
          <div className={styles.actionBtnInBar}>
            <CircleButton label="join the challenge" onClick={() => history.push('/submit')} />
          </div>
          <div className={styles.MenuBtn} onClick={() => setMenuOpen(pre => !pre)}>
            {menuOpen ? <Close /> : <Menu />}
          </div>
        </div>
      </div>
      <div className={styles.dropdownMenu} style={{ display: menuOpen ? 'block' : 'none' }}>
        <ul className={styles.NavigationItemsDD}>{LinkDDComponents}</ul>
        <div onClick={() => setMenuOpen(false)} className={styles.actionBtnDD}>
          <CircleButton label="join the challenge" onClick={() => history.push('/submit')} />
        </div>
      </div>
      <div
        className={styles.overlay}
        style={{ display: menuOpen ? 'block' : 'none' }}
        onClick={() => setMenuOpen(false)}
      />
    </>
  );
};
NavigationBar.propTypes = {
  history: ReactRouterPropTypes.history.isRequired
};
export default NavigationBar;
