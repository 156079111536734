import React from 'react';
import { Image } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { borderRadius, primaryColor, secondaryColor } from '../../assets/css/variables';
import TextLink from '../UI/TextLink/TextLink';

const divDate = {
  fontSize: '14px',
  fontWeight: 300,
  marginBottom: '17px',
  letterSpacing: 'normal',
  color: secondaryColor
};

const divTitle = {
  textTransform: 'uppercase',
  color: primaryColor,
  fontSize: '18px',
  letterSpacing: '1px',
  height: '90px',
  display: '-webkit-box',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  marginBottom: '20px'
};

const NewsCard = props => {
  const { imgURL, date, author, title, url } = props;

  return (
    <div style={{ margin: '0px 5px' }}>
      <Image
        src={imgURL}
        fluid
        style={{
          borderRadius,
          width: '100%',
          height: '200px',
          objectFit: 'cover',
          marginBottom: '23px'
        }}
      />

      <div style={divDate}>
        {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
        {date} | {author}
      </div>
      <p style={divTitle}>{title}</p>

      <TextLink target="_blank" href={url}>
        Read more
      </TextLink>
    </div>
  );
};
NewsCard.propTypes = {
  imgURL: PropTypes.string,
  date: PropTypes.string,
  author: PropTypes.string,
  title: PropTypes.string,
  url: PropTypes.string
};
NewsCard.defaultProps = {
  imgURL: '',
  date: '',
  author: '',
  title: '',
  url: ''
};
export default NewsCard;
