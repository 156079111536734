import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { borderRadius, primaryColor, shadow } from '../../assets/css/variables';
import { ReactComponent as KL } from '../../assets/line_icons/KL.svg';
import styles from './PSCard.module.css';

const PSCard = () => {
  return (
    <div
      style={{
        borderRadius,
        backgroundColor: primaryColor,
        padding: 8,
        color: 'white',
        boxShadow: shadow
      }}
    >
      <div style={{ borderRadius, border: '2px dashed white' }} className={styles.container}>
        <Row style={{ alignItems: 'center' }}>
          <Col md={1} style={{ padding: 0, textAlign: 'center' }}>
            <KL />
          </Col>
          <Col md="11" className={styles.contentCol}>
            <div className={styles.content}>
              Encourage freedom of mobility through the proficient usage of city infrastructure
              through a dynamic, intelligent, data-driven & connected solution toward sustainable
              development and a shared prosperity
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default PSCard;
